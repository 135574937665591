import axios from "axios";
import { SMS_BASE_URL, SMS_NEW_URL } from "./config";

export const SendSMSAPI = () => {
  return {
    sendOTP: (req, res) =>
      axios
        .post(SMS_BASE_URL, { req })
        .then((response) => {
          res = response.data;
          return res;
        })
        .catch((error) => {
          res.json(error);
        }),
    sendOTPByNewPro: (req, res) =>
      axios
        .post(SMS_NEW_URL, { req })
        .then((response) => {
          res = response.data;
          return res;
        })
        .catch((error) => {
          res.json(error);
        }),
  };
};
