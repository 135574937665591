import React, { useState, useContext } from "react";

import { useLocation } from "react-router-dom";
import { PhotoIcon } from "@heroicons/react/20/solid";

import { uploadFileToServer } from "../../utils/UserAPI";
import generateSecretKey from "../../utils/keyGenerationUtils";
import { encryptDataBase64 } from "../../utils/encryptionUtils";

import { UserContext } from "../../context/user.context";

const LoyaltyCard = () => {
  const location = useLocation();
  const { hash, pathname, search } = location;
  const { currentUser } = useContext(UserContext);

  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    const maxFileSize = 2 * 1024 * 1024; // 2MB in bytes
    const allowedTypes = ["image/jpeg", "image/png"];

    if (selectedFile) {
      if (!allowedTypes.includes(selectedFile.type)) {
        setError("Only JPG and PNG files are allowed");
        setFile(null);
        return;
      }

      if (selectedFile.size > maxFileSize) {
        setError("File size exceeds 2MB");
        setFile(null);
        return;
      }

      setError("");
      setFile(selectedFile);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (file) {
      // Code Changes from here

      const secretKey = generateSecretKey();

      const encryptedCustomerInfo = encryptDataBase64(
        "ZulalFileUpload",
        secretKey
      );

      const encryptedData = {
        secretKey: secretKey,
        encrypteCustomerInfo: encryptedCustomerInfo,
      };

      const formData = new FormData();
      formData.append("file", file);
      formData.append("accountno", currentUser.customerCode);
      formData.append("filename", file.name);
      formData.append("tokenkey", encryptedData.secretKey);
      formData.append("data", encryptedData.encrypteCustomerInfo);

      const respose1 = await uploadFileToServer(formData);

      if (respose1.message === "Success") {
        setMessage("File uploaded successfully");
        setFile(null);
      }

      // You can now proceed with file upload logic
    }
  };

  // useEffect(() => {
  //   console.log("first");

  //   const username = "yourUsername";
  //   const password = "yourPassword";

  //   const authHeader = "Basic " + btoa(username + ":" + password);

  //   const fileUploadTokenResponse =
  //     CallCenterAPI().getFileUploadToken(authHeader);

  //   fileUploadTokenResponse
  //     .then((response) => {
  //       console.log("response");
  //       if (response.status === 200) {
  //         console.log(response.token);
  //         setFileUploadToken(response.token);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //       setFileUploadToken("");
  //     });

  //   // const fileToken = fileTokenResponse.data.token;
  //   // if (!fileToken) {
  //   //   setError("Failed to get file upload token.");
  //   //   return;
  //   // }
  //   // console.log(fileToken);
  // }, [fileUploadToken]);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="text-navarbarText text-3xl font-poppins font-medium mb-8">
          Zulal Partners Card
          <p className="text-base text-darkTextColor font-poppins font-normal">
            <br /> One image can be upload at a time. Please allow 24 hours to
            validate the card and apply price list to your account.
          </p>
        </div>
      </div>

      <div className="flex items-start justify-self-start font-poppins">
        <div className="col-span-full">
          <form onSubmit={handleSubmit}>
            <label
              htmlFor="cover-photo"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Zulal Partners Card Upload
            </label>
            <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
              <div className="text-center">
                <PhotoIcon
                  aria-hidden="true"
                  className="mx-auto h-12 w-12 text-gray-300"
                />
                <div className="mt-4 flex text-sm leading-6 text-gray-600">
                  <label
                    htmlFor="file-upload"
                    className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                  >
                    <p className="font-poppins text-primary hover:text-primaryHover">
                      Upload a file
                    </p>
                    <input
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      className="sr-only"
                      onChange={handleFileChange}
                    />
                  </label>
                  <p className="pl-1">or drag and drop</p>
                </div>
                <p className="text-xs leading-5 text-gray-600">
                  PNG, JPG up to 2MB
                </p>
              </div>
            </div>
            {error && <p className="text-red-500 text-xs mt-2">{error}</p>}
            <div className="mt-6">
              <button
                type="submit"
                className="w-full px-4 py-2 text-sm font-medium leading-5 text-textWhite bg-secondary hover:bg-secondaryHover border border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
              >
                Submit
              </button>
            </div>
            {message && (
              <p className="text-green-500 text-xs mt-2">{message}</p>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoyaltyCard;
