import React from "react";
import { Breadcrumbs } from "../components/navbar/Breadcrumbs";

export const RefundPolicy = () => {
  return (
    <div>
      <div className="bg-pagePattern bg-center">
        <div className="flex mx-auto max-w-screen-lg text-center justify-center items-center mb-9 h-32  ">
          <span className="text-7xl text-textWhite font-medium  ">
            Refund Policy
          </span>
        </div>
      </div>

      <div className="ml-4">
        <Breadcrumbs />
      </div>

      <div className="bg-textWhite py-2 md:py-4">
        <div className="mx-auto max-w-7xl px-6 lg:px-8 ">
          <div className="">
            <h2 className="text-xl font-bold tracking-tight text-primary sm:text-4xl">
              Refund Policy Details
            </h2>
            <p className="mt-2 text-lg leading-8 text-textgray">
              At Zulal, our utmost priority is your satisfaction with your
              purchase. In the event that you are not entirely pleased with your
              Zulal product, we provide a straightforward process for returns
              and exchanges. To initiate a return or exchange, kindly reach out
              to our customer service team within 7 days of receiving your
              order.
            </p>
          </div>

          <div className="">
            <h2 className="text-xl font-bold tracking-tight text-primary sm:text-4xl">
              1. Eligibility Criteria:
            </h2>
            <p className="mt-2 text-lg leading-8 text-textgray">
              Products must maintain their original condition, be unused, and
              include all original packaging.
            </p>
          </div>
          <div className="">
            <h2 className="text-xl font-bold tracking-tight text-primary sm:text-4xl">
              2. Return Process:
            </h2>
            <p className="mt-2 text-lg leading-8 text-textgray">
              Contact our customer service team at info.zulal@sewa.gov.ae or
              call 600556666 to provide the necessary details. Upon receiving
              the returned item, we will promptly inspect it and process your
              refund or exchange within 7 business days.
            </p>
          </div>
          <div className="">
            <h2 className="text-xl font-bold tracking-tight text-primary sm:text-4xl">
              3. Refund Policy:
            </h2>
            <p className="mt-2 text-lg leading-8 text-textgray">
              Refunds will be issued through the original payment method used
              during the purchase.
            </p>
          </div>
          <div className="">
            <h2 className="text-xl font-bold tracking-tight text-primary sm:text-4xl">
              4. Defective or Damaged Items:
            </h2>
            <p className="mt-2 text-lg leading-8 text-textgray">
              If you happen to receive a defective or damaged item, please get
              in touch with us immediately. We will swiftly arrange for a
              replacement or issue a refund.
            </p>
          </div>

          <div className="">
            <h2 className="text-xl font-bold tracking-tight text-primary sm:text-4xl">
              5. Contact Information:
            </h2>
            <p className="mt-2 text-lg leading-8 text-textgray">
              • Customer Service Email: info.zulal@sewa.gov.ae <br />• Customer
              Service Phone: 600556666
            </p>
          </div>

          <div className="">
            <h2 className="text-xl font-bold tracking-tight text-primary sm:text-4xl">
              6. Policy Updates:
            </h2>
            <p className="mt-2 text-lg leading-8 text-textgray">
              We retain the right to update or modify our return policy as
              needed. Any changes will be communicated through our website.
            </p>
          </div>

          <div className="mb-44">
            <p className="mt-2 text-lg leading-8 text-textgray">
              Thank you for choosing Zulal. Your business is truly appreciated,
              and our goal is to ensure your shopping experience is as enjoyable
              as possible.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
