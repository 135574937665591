import React from "react";
import TestimonialsCards from "../../components/testimonials/TestimonialsCards";

const testimonials = [
  {
    id: "1",
    name: "Shahzeb Khan",
    message:
      "As a R&D engineer day is full of stress and specially for me, I need a fresh my mind to continue my work. Compare to other competitors in the market I found Zulal is the best option to use due to its quality.",
    date: "15/01/2023",
    designation: "R&D Engineer",
  },
  {
    id: "2",
    name: "Muhammad Waseem",
    message:
      "A testimonial is effectively a review or recommendation from a client, letting other people know how your products or services benefitted them.",
    date: "15/01/2023",
    designation: "Software Engineer",
  },
  {
    id: "3",
    name: "Dr. Muhammad Shahid",
    message:
      "Pink Caravan - The UAE Breast Cancer Initiative that falls under of the Friends of Cancer Patients Association umbrella 'Kashf' for early detection of cancer. It was launched...",
    date: "15/01/2023",
    designation: "CEO BMW",
  },
];

const Testimonials = () => {
  return (
    <React.Fragment>
      <div
        className="flex flex-col mx-auto 
               items-center justify-items-center justify-center 
               mt-4 mb-8 py-16"
      >
        <div className="text-6xl md:text-9xl dark:text-white">
          <svg
            width="42"
            height="35"
            viewBox="0 0 42 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.9249 2.43891L16.4009 0C5.85764 7.37736 0 16.3402 0 23.7176C0 30.8512 5.03759 34.2045 9.31316 34.2045C14.7019 34.2045 18.5094 29.4488 18.5094 24.4493C18.5094 20.2422 15.932 16.6451 12.4764 15.3037C11.4806 14.9379 10.5437 14.6331 10.5437 12.8648C10.5432 10.6077 12.1231 7.25569 17.9249 2.43891ZM41.1792 2.43891L39.6552 0C29.2289 7.37736 23.2543 16.3402 23.2543 23.7176C23.2543 30.8512 28.4088 34.2045 32.6848 34.2045C38.132 34.2045 41.998 29.4488 41.998 24.4493C42.0073 22.4763 41.4191 20.5467 40.3109 18.9143C39.2027 17.2819 37.6264 16.0232 35.7892 15.3037C34.7934 14.9379 33.9149 14.6331 33.9149 12.8648C33.9149 10.6077 35.555 7.25569 41.1779 2.43891H41.1792Z"
              fill="#00ADEF"
            />
          </svg>
        </div>

        <div className="text-xl font-normal text-secondary mt-4">
          TESTIMONIALS
        </div>

        <div>
          <span className="text-6xl text-primary md:text-9xl dark:text-white">
            OUR HAPPY
            <div className="font-bold uppercase"> Customers </div>
          </span>
        </div>

        {/* Testimonials Cards */}

        <TestimonialsCards testimonials={testimonials} />
      </div>
    </React.Fragment>
  );
};

export default Testimonials;
