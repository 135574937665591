import axios from "axios";
import {
  Sonic_Access_URL,
  Sonic_SearchCustomer_URL,
  Sonic_CustomerDetails_URL,
  Sonic_GetCountries_URL,
  Sonic_GetStates_URL,
  Sonic_GetCities_URL,
  Sonic_GetAreas_URL,
  Sonic_UpdateCustomerDetails_URL,
  Sonic_GetCustomerICash_URL,
  Sonic_GetCustomerPaymentsHistory,
  Sonic_GetCustomerOrdersHistory,
  Sonic_GetCustomerLast5Orders,
  Sonic_CreateNewCustomer_URL,
  Sonic_ICashTopUp_URL,
  Sonic_SendOrder_URL,
  Sonic_Save_ICashAdjustment,
  SEWA_SendEmail_URL,
  Sonic_UpdateCustomerInfo_URL,
  Sonic_FileUpload_Token_URL,
  Sonic_FileUpload_URL,
  OrderTrackingInfo_API_URL,
} from "./config";

export const CallCenterAPI = () => {
  const env_var = process.env.NODE_ENV;

  return {
    getCountries: (req, res) =>
      axios
        .get(Sonic_GetCountries_URL, { params: req })
        .then((response) => {
          res = response.data;
          return res;
        })
        .catch((err) => {
          console.log("Countries Error: " + err.message);
        }),

    getStates: (req, res) =>
      axios
        .get(Sonic_GetStates_URL, { params: req })
        .then((response) => {
          res = response.data;
          return res;
        })
        .catch((err) => {
          console.log("Call Center States Error: " + err.message);
        }),

    getCities: (req, res) =>
      axios
        .get(Sonic_GetCities_URL, { params: req })
        .then((response) => {
          res = response.data;
          //console.log("response from API call");
          //console.log(response.data);
          return res;
        })
        .catch((err) => {
          console.log(err.message);
        }),
    getAreas: (req, res) =>
      axios
        .get(Sonic_GetAreas_URL, { params: req })
        .then((response) => {
          res = response.data;
          //console.log("response from API call");
          //console.log(res);
          return res;
        })
        .catch((err) => {}),
    getUserByPhone: (req, res) =>
      axios
        .get(Sonic_SearchCustomer_URL, { params: req })
        .then((response) => {
          //console.log(response);
          res = response.data;
          return res;
        })
        .catch((err) => {
          console.log(err);
        }),
    getSonicCustomerAccessToken: (req, res) =>
      axios
        .post(Sonic_Access_URL, {}, { params: req })
        .then((response) => {
          if (env_var === "development") {
            console.log(response.data);
          }
          res = response.data;
          return res;
        })
        .catch((error) => {
          if (env_var === "development") {
            console.log(error.message);
          }
          return error;
        }),
    getCustomerDetails: (req, res) =>
      axios
        .get(Sonic_CustomerDetails_URL, {
          params: req,
        })
        .then((response) => {
          //console.log("Customer Details Data");
          if (env_var === "development") {
            console.log("development mode enabled");
            console.log(response.data);
          }
          res = response.data;
          return res;
        })
        .catch((error) => {
          res = error;
          return res;
        }),

    updateCustomerDetails: (req, res) =>
      axios
        .post(Sonic_UpdateCustomerDetails_URL, req)
        .then((response) => {
          //console.log("Customer Details Updated");
          res = response.data;
          return res;
        })
        .catch((error) => {
          res = error.message;
          return res;
        }),

    updateCustomerInfo: (req, res) =>
      axios
        .post(Sonic_UpdateCustomerInfo_URL, req)
        .then((response) => {
          const res = response.data;
          if (env_var === "development") {
            console.log("development mode enabled");
            console.log(response.data);
          }

          return res;
        })
        .catch((error) => {
          res = error.message;
          return res;
        }),

    ICashTopUp: (req, res) =>
      axios
        .post(Sonic_ICashTopUp_URL, { req })
        .then((response) => {
          //console.log(response);
          //console.log(response.data);
          res = response.data;
          return res;
        })
        .catch((error) => {
          res = error.message;
          return res;
        }),

    createNewcustomer: (req, res) =>
      axios
        .post(Sonic_CreateNewCustomer_URL, req)
        .then((response) => {
          //console.log(response.data.message);
          res = response.data;

          return res;
        })
        .catch((error) => {
          //console.log("Error Response:", error);
          res = error.message;
          return res;
        }),

    getCustomerICash: (req, res) =>
      axios
        .get(Sonic_GetCustomerICash_URL, { params: req })
        .then((response) => {
          //console.log("Customer Icash Data");

          if (env_var === "development") {
            //console.log(response.data);
          }
          res = response.data;
          //console.log(res);
          return res;
        })
        .catch((error) => {
          if (env_var === "development") {
            //console.log("error getting Icash :" + error.message);
          }
          res = error.message;
          return res;
        }),
    getCustomerPaymentsHistory: (req, res) =>
      axios
        .get(Sonic_GetCustomerPaymentsHistory, { params: req })
        .then((response) => {
          //console.log("Get Customer Payment History");
          //console.log(response.data);
          res = response.data;
          //console.log(res);
          return res;
        })
        .catch((error) => {
          //console.log("error getting Payment History :" + error.message);
          res = error.message;
          return res;
        }),

    getCustomerLast5Orders: (req, res) =>
      axios
        .get(Sonic_GetCustomerLast5Orders, { params: req })
        .then((response) => {
          //console.log("Customer Order History");
          //console.log(response.data);
          res = response.data;
          //console.log(res);
          return res;
        })
        .catch((error) => {
          //console.log("error getting Order History :" + error.message);
          res = error.message;
          return res;
        }),

    getCustomerOrdersHistory: (req, res) =>
      axios
        .get(Sonic_GetCustomerOrdersHistory, { params: req })
        .then((response) => {
          res = response.data;

          return res;
        })
        .catch((error) => {
          //console.log("error getting Order History :" + error.message);
          res = error.message;
          return res;
        }),
    sendSonicOrder: (req, res) =>
      axios
        .post(Sonic_SendOrder_URL, { req })
        .then((response) => {
          //console.log(response);
          res = response.data;
          return res;
        })
        .catch((error) => {
          //console.log(error);
          res = error.message;
          return res;
        }),
    saveICashAdjustment: (req, res) =>
      axios
        .post(Sonic_Save_ICashAdjustment, { req })
        .then((response) => {
          //console.log(response);
          res = response.data;
          return res;
        })
        .catch((error) => {
          //console.log(error);
          res = error.message;
          return res;
        }),
    sendEmail: (req, res) =>
      axios
        .post(SEWA_SendEmail_URL, { req: req })
        .then((response) => {
          //console.log(response);
          res = response.data;
          return res;
        })
        .catch((error) => {
          res = error.data;
          return res;
        }),
    getFileUploadToken: (req, res) =>
      axios
        .post(
          Sonic_FileUpload_Token_URL,
          { req: req },
          {
            headers: {
              Authorization: "Basic " + btoa("admin" + ":" + "password"),
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log("No Response");
          console.log(response);
          res = response.data;
          return res;
        })
        .catch((error) => {
          //console.log(error);
          res = error.data;
          return res;
        }),
    fileUpload: (req, res) =>
      axios
        .post(Sonic_FileUpload_URL, req, {
          headers: {
            Authorization: `Bearer ` + req.token,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          //console.log("File Upload Response");
          //console.log(response);
          res = response.data;
          return res;
        })
        .catch((error) => {
          //console.log(error);
          res = error.data;
          return res;
        }),
  };
};

//getcustomerICash: (req, res) =>{} // is wrong syntax
//getcustomerICash: (req, res) => axios.get .... is correct syntax
