import HeroSection from "../components/header/HeroSection";
import SafeWater from "../spa/SafeWater";
import WhyZulal from "../spa/WhyZulal";
import NewWaterQualities from "../spa/NewWaterQualities";
import DidYouKnow from "../spa/DidYouKnow";
import FreeHomeDelivery from "../spa/FreeHomeDelivery";
import Testimonials from "../spa/testimonials/Testimonials";
import { HomePageProducts } from "../spa/homePageProducts/HomePageProducts";
import NewsAndMedia from "../spa/newsandmedia/NewsAndMedia";
import HomeProductCard from "./homePageProducts/HomeProductCard";
import MostPopularProducts from "./MostPopularProducts";

export const Home = () => {
  return (
    <div>
      <div
        className="mx-auto bg-hero-patternsm md:bg-hero-patternlg bg-contain lg:bg-cover bg-no-repeat 
                      bg-herobgpositionsm md:bg-herobgposition  mb-10 "
      >
        <div id="target1" className="mx-auto max-w-screen-xl">
          <HeroSection />
        </div>
      </div>

      <div className="mx-auto ">
        <div id="target2" className="mx-auto max-w-screen-xl">
          <WhyZulal />
        </div>
      </div>

      <div className="mx-auto max-w-screen-xl">
        <SafeWater />
      </div>
      <div
        hidden
        className="mx-auto mt-8 bg-productsBackground bg-cover bg-no-repeat"
      >
        <div className="mx-auto max-w-screen-xl">
          <HomePageProducts />
        </div>
      </div>
      <div className="mx-auto rounded-md bg-primary bg-gradient-to-r from-primary to-primary items-center justify-center">
        <div className="mx-auto max-h-lvh	 ">
          <MostPopularProducts />
        </div>
      </div>

      <div className="mx-auto ">
        <div className="mx-auto max-w-screen-xl ">
          <NewWaterQualities />
        </div>
      </div>

      <div className="mx-auto mt-32 md:mt-0">
        <div className="mx-auto max-w-screen-xl ">
          <DidYouKnow />
        </div>
      </div>

      <div className="mx-auto mt-8 ">
        <div className="mx-auto max-w-screen-xl">
          <FreeHomeDelivery />
        </div>
      </div>

      <div hidden className="mx-auto mt-8">
        <div className="mx-auto ">
          <NewsAndMedia />
        </div>
      </div>
      <div hidden className="mx-auto top-auto ">
        <Testimonials />
      </div>
    </div>
  );
};
