import React, { useEffect, useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { CallCenterAPI } from "../../utils/callcenterAPI";
import { OrdersAPI } from "../../utils/OrdersAPI";
import { UserContext } from "../../context/user.context";

import ScaleLoader from "react-spinners/ScaleLoader";

export const OrderConfirmation = () => {
  const [loading, setLoading] = useState(false);

  // Sonic Requried Variables
  const [customerToken, setCustomerToken] = useState("");
  const [customerCode, setCustomerCode] = useState("");

  //NI Load Order requried variables
  const [paymentState, setPaymentState] = useState("");

  const [SonicOrderMessages, setSonicOrderMessages] = useState("");

  const [SonicOrderErrorMessages, setSonicOrderErrorMessages] = useState("");

  const {
    setCurrentUser,
    setCustomerDetails,
    setAccessToken,
    setSonicAccessToken,
  } = useContext(UserContext);

  //query variable to load the order from reference
  let query = useQuery();

  const [orderId, setOrderId] = useState("");

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  //get Order Information from NI

  const newOrderConfirmation = async (orderId) => {
    const params = { orderId: orderId };

    const orderResponse = await OrdersAPI().PostOrderConfirmation(params);

    // console.log("*********");
    // console.log(orderResponse);
    // console.log(orderResponse.statuscode);
    // console.log("***********");

    if (orderResponse.statuscode !== 200) {
      if (orderResponse.statuscode === 503) {
        setSonicOrderMessages("Unable to get Response");
        setSonicOrderErrorMessages("please contact with customer support!!!");
        setPaymentState("NotProcess");
        setOrderId("Not Available");
        return;
      }

      if (orderResponse.statuscode === 404) {
        setSonicOrderMessages("Order Not Placed");
        setSonicOrderErrorMessages(orderResponse.message);
        setPaymentState("NotProcess");
        setOrderId("Not Available");
        return;
      }

      if (orderResponse.statuscode === 202) {
        if (
          orderResponse.orderState === "STARTED" ||
          orderResponse.orderState === "FAILED"
        ) {
          setSonicOrderMessages("Order Not Placed");
          setSonicOrderErrorMessages(orderResponse.message);
          setPaymentState("NotProcess");
          setOrderId(orderId);
          return;
        } else if (orderResponse.orderState === "PURCHASED") {
          setSonicOrderMessages("Online Payment Received");
          setSonicOrderErrorMessages(
            "Please contact customer Support for your order delivery!!!"
          );
          setPaymentState("UnderProcess");
          setOrderId(orderId);
          return;
        }
      }

      if (orderResponse.statuscode === 203) {
        setSonicOrderMessages("Order Not Placed");
        setSonicOrderErrorMessages(orderResponse.message);
        setPaymentState("NotProcess");
      }

      if (orderResponse.statuscode === 404) {
        setSonicOrderMessages("Order Not Placed");
        setSonicOrderErrorMessages(orderResponse.message);
        setOrderId(orderId);
        if (
          orderResponse.orderState === "STARTED" ||
          orderResponse.orderState === "FAILED"
        ) {
          setPaymentState("NotProcess");
        } else if (orderResponse.orderState === "PURCHASED") {
          setSonicOrderMessages("Online Payment Received");
          setSonicOrderErrorMessages(
            "Please contact customer Support for your order delivery!!!"
          );
          setPaymentState("UnderProcess");
        }
      }

      if (orderResponse.statuscode === 204) {
        setSonicOrderMessages("Order Placement Issue");
        setSonicOrderErrorMessages(
          orderResponse.message + ", Order State:" + orderResponse.orderState
        );
        setPaymentState("UnderProcess");
      }

      if (orderResponse.statuscode === 205) {
        setSonicOrderMessages("Order Placed");
        setSonicOrderErrorMessages(
          orderResponse.message + ", Order State:" + orderResponse.orderState
        );
        setPaymentState("UnderProcess");
      }

      const params = {
        customerToken: orderResponse.customerToken,
        customerCode: orderResponse.customerCode,
      };

      getCustomerDetails(params);
    } else if (orderResponse.statuscode === 200) {
      setSonicOrderMessages("Thank you for your Order");
      setSonicOrderErrorMessages(orderResponse.message);
      // setCustomerCode(orderResponse.customerCode);
      // setCustomerToken(orderResponse.customerToken);
      setPaymentState("PURCHASED");

      // console.log(orderResponse.customerCode);
      // console.log(orderResponse.message);
      // console.log(orderResponse.paymentState);

      setAccessToken(orderResponse.customerToken);
      setSonicAccessToken(orderResponse.customerToken);

      const params = {
        customerToken: orderResponse.customerToken,
        customerCode: orderResponse.customerCode,
      };

      getCustomerDetails(params);

      //console.log("Order Paid Successfully");
      setSonicOrderMessages("Order Paid successfully");
    }
  };

  useEffect(() => {
    setLoading(true);

    const orderId = query.get("ref");

    //console.log(`orderId : ${orderId}`);

    if (orderId && orderId.length === 36) {
      setOrderId(orderId);
    } else {
      setOrderId(null);
      console.log("Order Reference not there");
    }

    setLoading(false);
  }, []);

  //load the order from NI

  function getLastNCharacters(str, n) {
    return str.substr(Math.max(str.length - n, 0)).toUpperCase();
  }

  useEffect(() => {
    if (orderId !== "") {
      newOrderConfirmation(orderId);
    }
  }, [orderId]);

  const getCustomerDetails = async (params) => {
    if (Object.keys(params).length !== 0) {
      const CustomerDetails = CallCenterAPI().getCustomerDetails(params);
      const customerCode = params.customerCode;
      // console.log("-----------");
      // console.log(customerCode);
      // console.log("---------------");

      CustomerDetails.then((detailres) => {
        // console.log(detailres);

        const { name, email } = detailres.data;
        const phoneNo = detailres.data.numbers[0].number;
        const address = detailres.data.addresses[0].address;

        setCustomerDetails({
          name: name,
          email: email,
          source: "web",
          DOB: "1990-01-01",
          AreaId: detailres.data.areaId,
          landmark: detailres.data.landmark,
          bldgName: detailres.data.bldgName,
          countryID: detailres.data.countryID,
          stateID: detailres.data.stateID,
          cityID: detailres.data.cityID,
          flatNumber: detailres.data.flatNumber,
          postcode: "000000",
          Currency: "AED",
          GroupCode: "CS",
          addresses: [
            {
              address: address,
              is_Default: true,
            },
          ],
          numbers: [
            {
              number: phoneNo,
              is_Default: true,
              CountryId: 1,
            },
          ],
        });

        const myUser = {
          customerCode: customerCode,
          outletName: name,
          email: email,
          phoneNo: phoneNo,
          customerAdderss: address,
          OTP: "",
          isloggedIn: true,
        };

        //console.log(myUser);
        setCurrentUser(myUser);
      }).catch((error) => {
        console.log(error.message);
        setSonicOrderMessages("SError:Unable to get Customer Information");
      });
    }
  };

  return (
    <div className="mb-60">
      <div className="flex flex-col mx-auto items-center justify-center text-center justify-items-center">
        <div>
          <ScaleLoader
            color="#36d7b7"
            height={40}
            loading={loading}
            radius={2}
            width={4}
          />
        </div>

        {paymentState === "UnderProcess" || paymentState === "NotProcess" ? (
          <div className="flex flex-col md:flex-row mx-auto items-center justify-center text-center justify-items-center">
            <div>
              <img
                src="../../images/OrderCancel.jpg"
                width={450}
                alt="Order Canceled"
              />
            </div>
            <div className="max-w-md">
              <span className="text-6xl m-3 font-bold text-center text-primary md:text-8xl md:font-extrabold md:text-left md:max-w-xl">
                {SonicOrderMessages} <br />
                <div className="text-base text-red-600 font-normal md:text-xl m-3">
                  {SonicOrderErrorMessages} <br />
                  Your purchased order # is : {getLastNCharacters(
                    orderId,
                    6
                  )}{" "}
                  <br />
                  with payment status : {paymentState} <br />
                </div>
                <br />
                <div className="text-base text-cool-gray-400 font-normal md:text-xl m-3">
                  If you have any questions or concerns, please don't hesitate
                  to reach out to our customer support team at 600-55-66-66. We
                  are here to assist you in any way we can.
                </div>
              </span>

              {customerCode !== null && customerToken !== null ? (
                <div>
                  <Link
                    to="../user/dashboard"
                    state={{ PageTitle: "Dashboard" }}
                    type="button"
                    className="flex w-full items-center justify-center rounded-md border border-transparent bg-primary mt-4 py-2 
        text-textWhite hover:bg-primaryHover focus:outline-none focus:ring-2 focus:ring-cool-gray-400 focus:ring-offset-2"
                  >
                    <span className="">Dashboard Access</span>
                  </Link>
                </div>
              ) : (
                <div>
                  <Link
                    to="../login"
                    state={{ PageTitle: "Login using OTP" }}
                    type="button"
                    className="flex w-full items-center justify-center rounded-md border border-transparent bg-primary mt-4 py-2 
    text-textWhite hover:bg-primaryHover focus:outline-none focus:ring-2 focus:ring-cool-gray-400 focus:ring-offset-2"
                  >
                    <span className="">Login</span>
                  </Link>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="flex flex-col md:flex-row mx-auto items-center justify-center text-center justify-items-center">
            <div>
              <img
                src="../../images/orderConfirmation.jpg"
                width={450}
                alt="Top Up confirmation"
              />
            </div>
            <div className="max-w-md">
              <span className="text-6xl m-3 font-bold text-center text-primary md:text-8xl md:font-extrabold md:text-left md:max-w-xl">
                Thank for your order ! <br />
                <div className="text-base text-green-600 font-normal md:text-xl m-3">
                  Your purchased order # is : {getLastNCharacters(orderId, 6)}{" "}
                  <br />
                  with payment status : {paymentState} <br />
                </div>
                <div className="text-base hidden text-green-600 font-normal md:text-xl m-3">
                  {SonicOrderMessages}
                </div>
              </span>

              <Link
                to="../user/dashboard"
                state={{ PageTitle: "Dashboard" }}
                type="button"
                className="flex w-full items-center justify-center rounded-md border border-transparent bg-primary mt-4 py-2 
                      text-textWhite hover:bg-primaryHover focus:outline-none focus:ring-2 focus:ring-cool-gray-400 focus:ring-offset-2"
              >
                <span className="">Dashboard Access</span>
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
