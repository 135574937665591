import axios from "axios";
import {
  NewOrderConfirmation_API_URL,
  OrderTrackingInfo_API_URL,
} from "./config";

export const OrdersAPI = () => {
  const env_var = process.env.NODE_ENV;

  return {
    PostOrderConfirmation: (req, res) =>
      axios
        .post(NewOrderConfirmation_API_URL, req)
        .then((response) => {
          res = response.data;
          return res;
        })
        .catch((err) => {
          if (env_var === "development") {
            console.log("Development mode enabled");
            console.log("Orders-Confirmation:ERROR " + err.message);
          } else if (env_var === "production") {
            console.log("Orders-Confirmation:ERROR - Unknown Error ");
          }
        }),
    orderTrackingInfo: (req, res) =>
      axios
        .get(OrderTrackingInfo_API_URL, { params: req })
        .then((response) => {
          res = response.data;
          return res;
        })
        .catch((err) => {
          if (env_var === "development") {
            console.log("Development mode enabled");
            console.log("Order-Tracking:ERROR " + err.message);
          } else if (env_var === "production") {
            console.log("Order-Tracking:ERROR - Unknown Error ");
          }
        }),
  };
};
