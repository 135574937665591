import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/user.context";
import { CallCenterAPI } from "../../utils/callcenterAPI";

import { UilBill, UilShoppingCart, UilSync } from "@iconscout/react-unicons";

export const UserInvoices = () => {
  const { sonicAccessToken } = useContext(UserContext);
  const [last5Payments, setLast5Payments] = useState(null);

  const customerToken = sonicAccessToken;

  useEffect(() => {
    if (customerToken !== null) {
      LoadData();
    }
  }, [customerToken]);

  function LoadData() {
    const params = { customerToken: customerToken };
    getCustomerPaymentsHistory(params);
  }

  function getCustomerPaymentsHistory(params) {
    if (Object.keys(params).length !== 0) {
      const customerPaymentsHistory =
        CallCenterAPI().getCustomerPaymentsHistory(params);

      customerPaymentsHistory
        .then((response) => {
          if (response.status === -1) {
            // console.log("NO Record Found");
            // console.log(response);
            setLast5Payments(response);
          } else if (response.status === 0) {
            //console.log(response);
            setLast5Payments(response);
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  }

  return (
    <div className="mx-auto mb-28">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="text-navarbarText text-3xl font-poppins font-medium mb-8">
            Invoices
            <p className="text-base text-darkTextColor font-poppins font-normal">
              List of all invoices with Amount
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              className="flex rounded-md bg-secondary h-10 w-10 items-center justify-center text-center"
              onClick={LoadData}
            >
              <UilSync className="w-6 text-textWhite" />
            </button>
          </div>
        </div>
        <div className="-mx-4 mt-8 sm:-mx-0">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                >
                  Invoice #
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  Date
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                >
                  Payment #
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                >
                  Type
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                >
                  Total Amount
                </th>

                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Details
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                  <span className="sr-only">Details</span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {last5Payments && last5Payments.data !== null ? (
                last5Payments.data.paymentList.map((invoice) => (
                  <tr key={invoice.invoiceNumber}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                      {invoice.invoiceNumber}
                    </td>
                    <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
                      {invoice.paymentDate}
                    </td>

                    <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                      {invoice.paymentNumber}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {invoice.paymentType}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {parseFloat(invoice.paidAmt).toFixed(2)}
                    </td>
                    <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      Details
                    </td>
                  </tr>
                ))
              ) : (
                <p>No record found</p>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
