import React from "react";
import { useSwiper } from "swiper/react";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";

export const NAMPrevButton = () => {
  const swiper = useSwiper();

  return (
    <button
      id="btnPrev1"
      onClick={() => swiper.slidePrev()}
      className="invisible sm:visible  w-10 h-10 text-primary rounded-full text-center align-text-center bg-secondary 
            justify-items-center items-center justify-center  hover:bg-secondaryHover hover:text-white "
      style={{ "text-align": "-webkit-center" }}
    >
      <ArrowLeftIcon className="w-6 h-6" />
    </button>
  );
};
