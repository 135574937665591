import React, { useRef, useEffect, useState } from "react";
import { useContext } from "react";
import { UserContext } from "../../context/user.context";
import { SendSMSAPI, updatePhoneNumber } from "../../utils/smsAPI";
import { CallCenterAPI } from "../../utils/callcenterAPI";
import generateSecretKey from "../../utils/keyGenerationUtils";
import { encryptDataBase64 } from "../../utils/encryptionUtils";

import { PhoneIcon } from "@heroicons/react/20/solid";

export const UserProfile1 = () => {
  const { currentUser, sonicAccessToken, setCurrentUser } =
    useContext(UserContext);

  const [user, setUser] = useState({});
  const [confirmOTP, setConfirmOTP] = useState("");
  const [mobileUpdate, setMobileUpdate] = useState(false);
  const [btnOTPText, setbtnOTPText] = useState("Send OTP");
  const [OTPText, setOTPText] = useState(0);
  const [messageText, setMessageText] = useState("");
  const [userAreaID, setUserAreaID] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [deleteText, setDeleteText] = useState("");

  const onChangeHandler = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });

    //console.log(e.target.name);
    if (e.target.name === "phoneNo") {
      setMessageText("You need to verify your Mobile Number");
      setMobileUpdate(true);
    }
  };

  let [userDetails, setUserDetails] = useState({});

  const customerToken = sonicAccessToken;
  const params = { customerToken: customerToken };

  const getCustomerDetails = async (params) => {
    //console.log("customer Data loaded");

    const objCustomerDetails = CallCenterAPI().getCustomerDetails(params);
    objCustomerDetails
      .then((detailres) => {
        userDetails = structuredClone(detailres.data);

        //console.log(userDetails);
        setUserAddress(userDetails.addresses[0].address);
        setUserAreaID(userDetails.areaId);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  // useEffect(() => {
  //   //console.log(`mobileUpdate:${mobileUpdate}`);
  // }, [mobileUpdate]);

  useEffect(() => {
    if (currentUser.customerCode !== null || currentUser.customerCode !== "") {
      let phone = currentUser.phoneNo;

      if (phone.length >= 10) {
        phone = phone.substring(phone.length - 9);
      }

      setUser({
        outletName: currentUser.outletName,
        customerCode: currentUser.customerCode,
        phoneNo: phone,
        email: currentUser.email,
        address: userAddress,
      });

      //console.log("Set User", user);
      //console.log("User Details", userDetails);
    }
  }, [currentUser]);

  useEffect(() => {
    if (sonicAccessToken !== null || sonicAccessToken !== undefined) {
      getCustomerDetails(params);
    }
  }, [sonicAccessToken]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = sonicAccessToken;

    if (token !== null && mobileUpdate === false) {
      const userUpdateDetails = {
        outletName:
          user.outletName !== "" ? user.outletName : currentUser.outletName,
        email: user.email !== "" ? user.email : currentUser.email,
        phoneNo: user.phoneNo !== "" ? user.phoneNo : currentUser.phoneNo,
      };

      const secretKey = generateSecretKey();
      const encryptedCustomerInfo = encryptDataBase64(
        userUpdateDetails,
        secretKey
      );
      const encryptedToken = encryptDataBase64(token, secretKey);

      const encryptedData = {
        secretKey: secretKey,
        encrypteCustomerInfo: encryptedCustomerInfo,
        encryptedToken: encryptedToken,
      };

      const params = { data: encryptedData };

      const updateCustRes = CallCenterAPI().updateCustomerInfo(params);

      updateCustRes
        .then((res) => {
          //console.log(res);

          if (res.status === 400) {
            //alert(res.message);
            setMessageText(res.message);
          } else if (res.status === 200) {
            //alert(res.message);

            setCurrentUser({
              outletName: user.outletName,
              customerCode: currentUser.customerCode,
              phoneNo: user.phoneNo,
              email: user.email,
              address: currentUser.address,
              OTP: "",
              smsType: "",
              isloggedIn: true,
            });

            setMessageText(res.message);
          }
        })
        .catch((err) => {
          console.log(err);
          setMessageText(err.message);
        });
    } else {
      setMessageText(
        "Either you didn't verified your OTP, or (email / Mobile) already exists with other account."
      );
    }
  };

  const updateMobile = (e) => {
    e.preventDefault();
    //console.log(user);

    if (sonicAccessToken != null) {
      const { phoneNo } = user;

      if (btnOTPText === "Send OTP") {
        const otp = generateRandomNumber();
        setOTPText(otp);
        user.OTP = otp;
        currentUser.OTP = otp;
        currentUser.phoneNo = phoneNo;

        const secretKey = generateSecretKey();
        //console.log("Generated Secret Key (Frontend):", secretKey);
        const encryptedMyData = encryptDataBase64(currentUser, secretKey);
        //console.log("Encrypted Data (Frontend):", encryptedMyData);
        //setEncryptedData(encryptedMyData);

        //const decryptedData1 = decryptDataBase64(encryptedMyData, secretKey);
        //console.log("Decrypted Data (Frontend):", decryptedData1);
        //setDecryptedData(decryptedData1);

        //console.log("after decrypting");

        const data = {
          encryptedData: encryptedMyData,
          secretKey: secretKey,
          smsType: "UpdatePhone",
        };

        const smsRes = SendSMSAPI().sendOTPByNewPro(data);

        smsRes
          .then((res) => {
            // console.log(res);
            // console.log(res.data);
            setMessageText("OTP sent successfully");
            setbtnOTPText("Verify OTP");
          })
          .catch((err) => {
            setMessageText(err.message);
          });
      } else if (btnOTPText === "Verify OTP") {
        if (OTPText == confirmOTP) {
          setMessageText("OTP Verified! You may Update the profile now.");
          setMobileUpdate(false);
          setbtnOTPText("Send OTP");
        } else {
          //console.log(OTPText, confirmOTP);
          setMessageText("OTP Not Verified!");
          setMobileUpdate(true);
        }
      }
    }
  };

  function generateRandomNumber() {
    var minm = 100000;
    var maxm = 999999;
    return Math.floor(Math.random() * (maxm - minm + 1)) + minm;
  }

  const handleDeletion = (e) => {
    e.preventDefault();

    if (deleteText === "delete my account") {
      //console.log("Deletion");
      //CallCenterAPI().sendEmail()
    } else {
      //console.log("please type 'delete my account'");
    }
  };

  return (
    <div>
      <div className="mx-auto mr-4 ml-4 mb-28">
        <div className="text-navarbarText text-3xl font-poppins font-medium mb-8">
          Profile
          <p className="text-darkTextColor font-poppins text-C12">
            Update your profile including Full Name, Email and Phone Number
          </p>
        </div>
        <div className="grid grid-cols-1 gap-x-8 gap-y-6 md:grid-cols-2">
          <div>
            <label
              htmlFor="customerCode"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Customer Code
            </label>

            <div className="mt-2.5">
              <input
                type="text"
                value={user.customerCode}
                readOnly
                name="customerCode"
                id="customerCode"
                placeholder="Customer Code"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-secondaryHover
                   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="outletName"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Full Name
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                value={user.outletName}
                onChange={onChangeHandler}
                name="outletName"
                id="outletName"
                autoComplete="given-name"
                placeholder="Full Name"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-secondaryHover
                       placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="email"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Email
            </label>
            <div className="mt-2.5">
              <input
                type="email"
                name="email"
                id="email"
                value={user.email}
                onChange={onChangeHandler}
                autoComplete="email"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-secondaryHover
                       placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="phoneNo"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Phone number
            </label>

            <div className="flex flex-row mt-2.5">
              <span className="bg-darkVeryLight inline-flex items-center rounded-l-md border border-r-0 ring-1 ring-inset border-secondaryHover px-3 py-2 text-gray-500 sm:text-sm">
                <PhoneIcon className="text-darkTextColor w-6" />
                <div className="flex font-normal text-P14 w-10 items-end justify-end">
                  +971
                </div>
              </span>

              <input
                type="tel"
                pattern="[0-9]{9}"
                name="phoneNo"
                id="phoneNo"
                required
                value={user.phoneNo}
                onChange={onChangeHandler}
                autoComplete="tel"
                className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 
                focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                invalid:border-red-500 invalid:text-red-600 focus:invalid:border-red-500 focus:invalid:ring-red-500"
              />
            </div>
          </div>

          {mobileUpdate && (
            <div className="grid grid-flow-col md:col-span-2 space-x-5">
              <div>
                <label
                  htmlFor="otp"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  Confirm OTP
                </label>
                <div className="mt-2.5">
                  <input
                    type="otp"
                    name="otp"
                    id="otp"
                    onChange={(e) => {
                      setConfirmOTP(e.target.value);
                    }}
                    className="block w-full max-w-xs rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-secondaryHover
                       placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="btnOTP"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  Update Mobile
                </label>
                <div className="mt-2.5">
                  <input
                    type="button"
                    name="btnOTP"
                    id="btnOTP"
                    value={btnOTPText}
                    onClick={updateMobile}
                    className="rounded-md bg-secondary  px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-secondaryHover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="mt-8 flex justify-end">
          <button
            type="submit"
            onClick={handleSubmit}
            className="rounded-md bg-secondary disabled:bg-darkVeryLight  px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-secondaryHover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Update Profile
          </button>
        </div>
        <div className="mt-8 flex justify-start">
          <label className="block text-sm font-semibold leading-6 text-gray-900">
            {messageText}
          </label>
        </div>
        <div className="hidden">
          <div className="text-navarbarText text-3xl font-poppins font-medium mb-8 mt-8">
            Account Deletion
            <p className="text-darkTextColor font-poppins text-C12 mt-2">
              Request the Closure of Your Account and the Deletion of Your
              Personal Information
            </p>
            <p className="text-darkTextColor font-poppins text-C12 mt-2">
              You can submit a request for us to close your Zulal account and
              delete your personal information by typing the message in below
              text box <b> "delete my account"</b>
            </p>
            <p className="text-red-500 font-poppins text-base mt-2">
              Kindly be informed that your personal details, orders, payments,
              and returns will undergo permanent deletion. <br />
              This process will require 48 hours to finalize. In case of
              accidental submission, please feel free to reach out to us at{" "}
              <b>600 55 6666</b>.
            </p>
          </div>
        </div>
        <div className="hidden">
          <label
            htmlFor="deleteAccount"
            className="block text-sm font-semibold leading-6 text-gray-900"
          >
            Confirmation Message
          </label>
          <div className="mt-2.5">
            <input
              type="text"
              placeholder="delete my account"
              onChange={(e) => setDeleteText(e.target.value)}
              name="deleteAccount"
              id="deleteAccount"
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-secondaryHover
                 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div className="mt-8 flex justify-end hidden">
          <button
            type="button"
            onClick={handleDeletion}
            className="rounded-md bg-secondary disabled:bg-darkVeryLight  px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-secondaryHover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Account Deletion
          </button>
        </div>
      </div>
    </div>
  );
};
