import React, { useState } from "react";

import {
  MinusIcon,
  PlusIcon,
  ShoppingBagIcon,
} from "@heroicons/react/24/solid";

import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Mousewheel, Navigation, Pagination, Scrollbar } from "swiper";
import SlideNextButton from "./SlideNextButton";
import SlidePreviousButton from "./SlidePreviousButton";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import "./styles.css";
import { Link } from "react-router-dom";

function SwiperTest({ products }) {
  const [quantity, setQuantity] = useState(0);

  return (
    <div className="items-center justify-center text-center">
      <Swiper
        pagination={{
          clickable: true,
        }}
        scrollbar={{
          hide: true,
        }}
        breakpoints={{
          384: {
            slidesPerView: 1,
            centeredSlides: true,
          },
          480: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          834: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          1440: {
            slidesPerView: 4,
            spaceBetween: 0,
          },
        }}
        modules={[Pagination, Navigation, Scrollbar, Mousewheel, A11y]}
        className="max-w-xs sm:max-w-2xl md:max-w-4xl lg:max-w-6xl "
        style={{ "text-align": "-webkit-center" }}
      >
        {products.map((product) => (
          <SwiperSlide key={product.id}>
            <div className="w-56 bg-primaryVeryLight rounded-lg p-4 ">
              <div className="w-48 bg-productBackground rounded-lg">
                <img
                  src={product.imageSrc}
                  className="h-48 transition duration-300 hover:scale-110 cursor-pointer"
                  alt={product.imageAlt}
                />

                {product.instock && (
                  <div className="flex flex-row">
                    <div className=" absolute top-0 right-10">
                      <div className="rounded-b-md bg-primary w-14 h-14"></div>
                    </div>

                    <div className="absolute top-0 right-11">
                      <span className="flex flex-col text-3xl font-medium text-textWhite">
                        {product.discount}%
                        <span className="text-C11 font-medium text-textWhite">
                          Off
                        </span>
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <h3 className="text-center font-bold text-sm mt-2">
                {product.name}
              </h3>
              <p className="text-center font-normal text-C12 mb-2">
                {String(product.description).substring(0, 30) + " ..."}
              </p>

              <div className="flex flex-row justify-evenly mb-2">
                {product.instock ? (
                  <p className="mt-2 text-xl font-bold text-white line-through ">
                    {product.actualprice}
                  </p>
                ) : (
                  <p className="mt-2 text-xl font-bold text-white ">
                    {product.price}
                  </p>
                )}

                {product.instock && (
                  <p className="mt-2 text-xl font-bold text-white">
                    {(
                      parseFloat(product.actualprice) -
                      parseFloat(product.actualprice) * 0.2
                    ).toFixed(2)}{" "}
                    AED
                  </p>
                )}
              </div>
              <div className="flex flex-row gap-3 items-center justify-center">
                <Link
                  to="/productsrange"
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: "smooth",
                    });
                  }}
                  className="flex flex-row w-full  p-1 items-center justify-center border-[1px] border-secondary rounded-md 
                        font-openSans font-bold text-sm uppercase hover:bg-secondaryHover hover:text-textWhite text-secondary gap-2"
                >
                  Shop Now <ShoppingBagIcon className="w-6 h-6" />
                </Link>
              </div>
            </div>
          </SwiperSlide>
        ))}
        <div className="h-5"></div>
        <SlidePreviousButton /> {"       "} <SlideNextButton />
        <div className="h-10"></div>
      </Swiper>
    </div>
  );
}

export default SwiperTest;
