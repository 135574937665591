import React from "react";

export const Security = () => {
  return (
    <div>
      <form action="#" method="POST">
        <div className="mx-auto mr-4 ml-4 mb-28">
          <div className="text-navarbarText text-3xl font-poppins font-medium mb-8">
            Mobile App
            <p className="text-base text-darkTextColor font-poppins font-normal">
              You can update Mobile App Password over here.
            </p>
          </div>
          <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
            <div className="sm:col-span-2">
              <label
                htmlFor="appID"
                className="block text-sm font-semibold leading-6 text-gray-900"
              >
                App Id
              </label>
              <div className="mt-2.5">
                <input
                  type="text"
                  name="appID"
                  id="appID"
                  readOnly
                  placeholder="1125215"
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-secondaryHover
                   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="password"
                className="block text-sm font-semibold leading-6 text-gray-900"
              >
                Password
              </label>
              <div className="mt-2.5">
                <input
                  type="password"
                  name="password"
                  id="password"
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-secondaryHover
                   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
          <div className="mt-8 flex justify-end">
            <button
              type="submit"
              className="rounded-md bg-secondary  px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-secondaryHover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Send User Name to Mobile
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
