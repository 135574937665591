import { Link, useLocation } from "react-router-dom";

export const Breadcrumbs = () => {
  const location = useLocation();
  //console.log(location);

  let currentLink = "";
  const crumbs = location.pathname
    .split("/")
    .filter((crumb) => crumb !== "")
    .map((crumb) => {
      console.log(crumb);
      currentLink += `/${crumb}`;
      return (
        <div
          key={crumb}
          className="text-base text-darkLight font-light after:content-['>'] after:last:content-[''] after:ml-1"
        >
          <Link to={currentLink}>{crumb}</Link>
        </div>
      );
    });

  if (crumbs.length > 0) {
    return (
      <div className="flex flex-row mx-auto max-w-screen-lg overflow-hidden border-b-2 border-b-silver h-10 gap-3">
        {crumbs}
      </div>
    );
  }
};
