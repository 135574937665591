import React from "react";

const FreeHomeDelivery = () => {
  return (
    <React.Fragment>
      <div className="mx-auto px-6 py-8 md:max-w-5xl md:px-0 md:gap-14 mb-24 flex flex-col md:flex-row gap-8 ">
        <div className="mx-auto justify-center items-center max-w-md">
          <img
            src="/images/zulalvan.jpg"
            className="scale-100 max-w-sm md:scale-125 rounded-md w-auto md:w-96 drop-shadow-md"
            alt=""
          />
        </div>

        <div className="mx-auto max-w-sm  flex flex-col gap-6 items-start md:items-start justify-center md:justify-start">
          <span className="text-2xl md:text-4xl font-bold text-primary">
            Free Home Delivery
          </span>

          <span className="text-sm font-poppins text-textgray text-left md:text-left">
            Our services delivers the best quality premium water to your
            doorstep in all emirates.
          </span>

          <div className="flex flex-col items-start ">
            <div className="flex flex-col items-start gap-1 ">
              <div className="flex flex-row items-center gap-4">
                <img src="/images/check-mark.svg" alt="" />
                Order before 10 AM to get Same Day Delivery
              </div>

              <div className="flex flex-row items-center gap-4">
                <img src="/images/check-mark.svg" alt="" />
                Saturday - Thursday
              </div>

              <div className="flex flex-row items-center gap-4">
                <img src="/images/check-mark.svg" alt="" />
                08:00 AM to 06:00 PM
              </div>
            </div>
          </div>

          <span className="text-3xl md:text-4xl font-bold font-poppins text-secondary text-center">
            Call Us: 600556666
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FreeHomeDelivery;
