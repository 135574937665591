import React from "react";
import { Link } from "react-router-dom";

export const Slide1 = () => {
  return (
    <React.Fragment>
      <div className="mx-auto px-5 mt-1 flex flex-col items-center md:items-start justify-evenly md:flex-row">
        {/* Left div item md:w-1/2 */}
        <div className="flex flex-col font-poppins items-center md:items-start md:mt-2 space-y-8 ">
          <span className="max-w-md text-5xl font-bold text-center text-primary md:text-8xl md:font-extrabold md:text-left md:max-w-xl">
            100% Pure underground Water
            <span className="font-normal text-5xl md:text-9xl">
              {" "}
              directly from the{" "}
            </span>
            wells of SHARJAH
          </span>
          <p className="max-w-md font-normal text-xl text-justify text-darkTextColor  md:max-w-xl">
            One of its distinctive features is the sourcing of 100% pure
            underground water directly from the Wells of Sharjah, setting it
            apart as the exclusive provider of such pristine water in the area.
            Zulal Water remains committed to its mission of ensuring access to
            high-quality drinking water for all people within the Emirate.
          </p>

          <div className="flex items-center align-middle justify-center md:justify-start">
            <Link
              to="/productsrange"
              href="#about"
              className="inline-flex items-center justify-center w-auto md:w-44 px-5 py-3 mr-3 text-base font-medium 
              text-center text-textWhite rounded-full bg-primary
              hover:bg-primaryHover focus:ring-4 focus:ring-primary-300 dark:focus:ring-primaryLight"
            >
              Order Now
              <svg
                className="w-5 h-5 ml-2 -mr-1"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </Link>
          </div>
        </div>

        {/* right div item md:w-1/2 */}
        <div className="mt-8 sm:mb-24 md:mt-1">
          <img
            src="/images/WaterBottle.svg"
            alt="mockup"
            className="w-128 lg:w-144"
          />
        </div>
      </div>
    </React.Fragment>
  );
};
