import React from "react";
import { Breadcrumbs } from "../../components/navbar/Breadcrumbs";

export const AboutZulal = () => {
  return (
    <div>
      <div className="bg-pagePattern bg-center">
        <div className="flex mx-auto max-w-screen-lg text-center justify-center items-center mb-9 h-32  ">
          <span className="text-7xl text-textWhite font-medium  ">
            About Zulal
          </span>
        </div>
      </div>

      {/* Content section */}
      <div className="mt-8 overflow-hidden">
        <div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
            <div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
              <h2 className="text-3xl font-bold tracking-tight text-primary sm:text-4xl">
                About Zulal
              </h2>
              <p className="mt-3 text-xl leading-8 text-textgray">
                Zulal was established under the vision of H.H. Sheikh Dr. Sultan
                Bin Mohammed Al Qasimi, Supreme Court Member, and Ruler of
                Sharjah, to ensure access to clean pure drinking water for all
                residents of Sharjah. Founded in 1995, Zulal became the first
                government entity addressing this necessity. It remains the sole
                water company offering 100% pure underground water sourced
                directly from the Wells of Sharjah.
              </p>

              <h2 className="text-3xl mt-6 font-bold tracking-tight text-primary sm:text-4xl">
                Our Water
              </h2>
              <p className="mt-3text-base leading-7 text-textgray">
                One of its distinctive features is the sourcing of 100% pure
                underground water directly from the Wells of Sharjah, setting it
                apart as the exclusive provider of such pristine water in the
                area. Zulal Water remains committed to its mission of ensuring
                access to high-quality drinking water for all people within the
                Emirate.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
