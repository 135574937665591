import React from "react";

import { Routes, Route } from "react-router-dom";

import NavbarUI from "./components/navbar/NavbarUI";
import logo from "./images/ZulalLogo.svg";
import { Home } from "./spa/Home";
import { AboutZulal } from "./spa/about/AboutZulal";
import { Promotions } from "./spa/promotions/Promotions";
import { HowItWorks } from "./spa/promotions/HowItWorks";
import { Zulal } from "./spa/zulal/Zulal";
import { UserLogin } from "./spa/user/UserLogin";
import { Page404 } from "./spa/Page404";
import { ComingSoon } from "./spa/ComingSoon";
import { Contactus } from "./spa/contactus/contactus";
import { UserHome } from "./spa/user/UserHome";

import Footer from "./spa/footer/Footer";

import { UserLayout } from "./layouts/UserLayout";
import { UserProfile1 } from "./spa/user/UserProfile1";
import { Security } from "./spa/user/Security";

import { UserInvoices } from "./spa/user/UserInvoices";
import { Orders } from "./spa/user/Orders";
import { Logout } from "./spa/user/Logout";
import { ZulalCredits } from "./spa/user/ZulalCredits";
import { UserAddress } from "./spa/user/UserAddress";
import { UserPrivateRoutes } from "./utils/UserPrivateRoutes";

import { Dashboard } from "./spa/user/Dashboard";
import CreditCardPage from "./spa/user/CreditCardPage";

import { UserDetails } from "./spa/user/UserDetails";
import UserAddressUpdated from "./spa/user/UserAddressUpdated";

import CreateUser from "./spa/user/CreateUser";

import { TopUpConfirmation } from "./spa/user/TopUpConfirmation";
import NewsAndMediaPage from "./spa/newsandmedia/NewsAndMediaPage";
import { ProductsRange } from "./spa/shop/ProductsRange";
import { CheckOut } from "./spa/shop/CheckOut";
import { OrderConfirmation } from "./spa/user/OrderConfirmation";
import { RefundPolicy } from "./spa/RefundPolicy";
import LoyaltyCard from "./spa/user/LoyaltyCard";
import OrderTracking from "./spa/userOrderTracking/OrderTracking";

function App() {
  return (
    <React.Fragment>
      <NavbarUI logo={logo} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<AboutZulal />} />
        <Route path="zulal" element={<Zulal />} />
        <Route path="productsrange" element={<ProductsRange />} />
        <Route path="NewsMedia" element={<ComingSoon />} />
        <Route path="ContactUs" element={<Contactus />} />
        <Route path="ComingSoon" element={<ComingSoon />} />
        <Route path="topupconfirmation" element={<TopUpConfirmation />} />
        <Route path="orderconfirmation" element={<OrderConfirmation />} />
        <Route path="promotions" element={<Promotions />} />
        <Route path="howitworks" element={<HowItWorks />} />
        <Route path="orderTracking" element={<OrderTracking />} />

        <Route path="login" element={<UserLogin />} />
        <Route path="createuser" element={<CreateUser />} />

        <Route path="*" element={<Page404 />} />
        <Route path="refundpolicy" element={<RefundPolicy />} />

        <Route element={<UserPrivateRoutes />}>
          <Route path="/user" exact element={<UserLayout />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="userdetails" element={<UserDetails />} />
            <Route path="profile" element={<UserProfile1 />} />
            <Route path="address-1" element={<UserAddress />} />
            <Route path="address" element={<UserAddressUpdated />} />
            <Route path="security" element={<Security />} />
            <Route path="invoices" element={<UserInvoices />} />
            <Route path="zulalwallet" element={<ZulalCredits />} />
            <Route path="LoyaltyCard" element={<LoyaltyCard />} />
            <Route path="orders" element={<Orders />} />
            <Route path="CreditCardPayment" element={<CreditCardPage />} />
            <Route path="dashboard" element={<UserHome />} />
            <Route path="checkout" element={<CheckOut />} />
            <Route path="logout" element={<Logout />} />
          </Route>
        </Route>
      </Routes>

      <Footer />
    </React.Fragment>
  );
}

export default App;
