import React, { useContext, useEffect, useState } from "react";
import { ProductsContext } from "../../context/products.context";

import ProductCard1 from "./ProductCard1";
import { UserContext } from "../../context/user.context";

export const ProductsRange = () => {
  const { products, getMyProductsByCustomerGroup } =
    useContext(ProductsContext);

  const { currentUser, sonicAccessToken } = useContext(UserContext);

  const [localProducts, setLocalProducts] = useState();
  const [promoPrice, setPromoPrice] = useState(false);

  useEffect(() => {
    if (sonicAccessToken != null) {
      getMyProductsByCustomerGroup(sonicAccessToken);
    }
  }, []);

  return (
    <div className="bg-white">
      <div className="bg-pagePattern bg-center">
        <div className="flex mx-auto max-w-screen-lg text-center justify-center items-center mb-9 h-32  ">
          <span className="text-7xl text-textWhite font-medium">
            Zulal Store
          </span>
        </div>
      </div>

      <div className="flex mx-auto items-center justify-center max-w-2xl px-4 mb-8 py-8 sm:px-6 sm:py-8 lg:max-w-7xl lg:px-8">
        <div></div>
        <div className="mb-8 grid grid-cols-1 gap-y-12 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
          {products.map(
            (product) =>
              product.instock && (
                <ProductCard1 key={product.id} product={product} />
              )
          )}
        </div>
      </div>
    </div>
  );
};
