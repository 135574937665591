import {
  CloudArrowUpIcon,
  LockClosedIcon,
  ServerIcon,
} from "@heroicons/react/20/solid";

export const HowItWorks = () => {
  return (
    <div className="relative isolate overflow-hidden bg-white px-6 py-6 sm:py-8 lg:overflow-visible lg:px-0">
      <div className="absolute inset-0 -z-10 overflow-hidden">
        <svg
          className="absolute inset-0 h-full w-full stroke-secondaryHover [mask-image:radial-gradient(100%_100%_at_bottom_right,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
              width={200}
              height={200}
              x="100%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M130 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <rect width="100%" height="100%" strokeWidth={0} fill="white" />
          <svg
            x="100%"
            y={-1}
            className="overflow-visible fill-primaryVeryLight"
          >
            <path d="M-470.5 0h201v201h-201Z" strokeWidth={0} />
          </svg>
          <rect
            width="100%"
            height="100%"
            strokeWidth={0}
            fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)"
          />
        </svg>
      </div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-primary">
                Our Partners Card
              </p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-secondary sm:text-4xl">
                How it Works
              </h1>
              <p className="mt-6 text-xl leading-8 text-cool-gray-500">
                You can now enjoy exclusive discounts offered to our partners'
                network. One time registration process is required.
              </p>
            </div>
          </div>
        </div>
        <div className="-ml-32 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          <img
            alt=""
            src="../../images/promotions/Step1-LoginToYourAccount.png"
            className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
          />
          <img
            alt=""
            src="../../images/promotions/Step2-AfterLogin.png"
            className="w-[48rem] mt-8 max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
          />
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              <p>Please follow the instructions as given below.</p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-secondary sm:text-4xl">
                Step 1
              </h1>
              <ul role="list" className="mt-8 space-y-8 text-gray-600">
                <li className="flex gap-x-3">
                  <LockClosedIcon
                    aria-hidden="true"
                    className="mt-1 h-5 w-5 flex-none text-indigo-600"
                  />
                  <span>
                    <strong className="font-semibold text-gray-900">
                      Login to your account
                    </strong>{" "}
                    <p className="mt-6 text-xl leading-8 text-cool-gray-500">
                      Login to your account using your registered Mobile number
                      and get the OTP.
                    </p>
                  </span>
                </li>
              </ul>
            </div>

            <div className="max-w-xl text-base md-10 md:mt-16 leading-7 text-gray-700 lg:max-w-lg">
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-secondary sm:text-4xl">
                Step 2
              </h1>
              <ul role="list" className="mt-8 space-y-8 text-gray-600">
                <li className="flex gap-x-3">
                  <LockClosedIcon
                    aria-hidden="true"
                    className="mt-1 h-5 w-5 flex-none text-indigo-600"
                  />
                  <span>
                    <strong className="font-semibold text-gray-900">
                      After Login to your account
                    </strong>{" "}
                    <p className="mt-6 text-xl leading-8 text-cool-gray-500">
                      2.1 After Login to your account click on Partners Card
                      Link.
                    </p>
                    <p className="mt-6 text-xl leading-8 text-cool-gray-500">
                      2.2 Click on Upload a File, after selecting the file hit
                      on Submit button.
                    </p>
                    <p className="mt-6 text-xl leading-8 text-cool-gray-500">
                      2.3 Once you receive the success message, provide us 24
                      hours to apply the discounted rate on your profile.
                    </p>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
