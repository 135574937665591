import React, { useContext, useEffect, useState } from "react";
import { CartContext } from "../../context/cart.context";
import { Link } from "react-router-dom";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/solid";

const ProductCard1 = ({ product }) => {
  const {
    addItemToCart,
    cartItems,
    removeItemToCart,
    clearItemfromCart,
    cartCount,
  } = useContext(CartContext);

  const [promoPrice, setPromoPrice] = useState(false);

  const addProductToCart = () => {
    addItemToCart(product);
  };

  const removeProductToCart = () => {
    removeItemToCart(product);
  };

  //useEffect(() => {}, []);

  async function calculateDiscount(discount, price) {
    // const total =
    //   parseFloat(product.actualprice) -
    //   parseFloat(product.actualprice) * (product.discount / 100);
    // const discount = parseFloat(product.actualprice) - total;
    // const discountPercentage =
    //   (discount / parseFloat(product.actualprice)) * 100;
    // //return discountPercentage.toFixed(2);

    // console.log(discountPercentage.toFixed(2));

    console.log(discount, price);

    return discount * 100;
  }

  return (
    <div
      className="w-72 border-[1px] border-cool-gray-300 font-poppins shadow-md rounded-xl
      duration-500 hover:scale-105 bg-primaryLight hover:shadow-xl hover:bg-primaryVeryLight"
    >
      <a href="#">
        <div className="bg-primaryVeryLight rounded-t-xl">
          <img
            src={product.imageSrc}
            alt={product.imageAlt}
            className="h-80 w-72 object-contain scale-[.85] rounded-t-xl"
          />
        </div>

        <div className="group px-4 py-3 w-72 h-44 bg-primaryLight  hover:bg-secondary text-cool-gray-500 hover:text-textWhite rounded-t-2xl duration-500 hover:shadow-xl">
          <div className="flex flex-row gap-2 relative">
            {product.discount > 0 ? (
              <div className="flex flex-row">
                <div className="absolute -top-[332px] right-0">
                  <div className="rounded-b-md bg-primary w-14 h-14"></div>
                </div>
                <div className="absolute -top-[330px] right-[5px] text-center">
                  <span className="flex flex-col text-3xl font-medium text-textWhite">
                    {product.discount}%
                    <p className="text-base font-medium text-textWhite">Off</p>
                  </span>
                </div>
              </div>
            ) : product.promoPrice ? (
              <div className="flex flex-row justify-center items-center">
                <div className="absolute flex -top-[332px] right-0 rounded-b-md bg-primary w-14 h-14 justify-center">
                  <span className="flex flex-col text-3xl font-medium text-textWhite">
                    <p className="text-C12 font-medium text-textwhite">
                      Promo
                      <br /> Price
                    </p>
                  </span>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="flex flex-row justify-between gap-2">
            <div>
              <div className="mb-2 mt-2 max-w-[150px] uppercase text-xl">
                {product.description}
              </div>

              <p className="text-C14 text-cool-gray-400 truncate ">&nbsp;</p>
              <div className="flex items-center"></div>
              <div className="flex items-center">
                <p className="text-xl font-poppins font-semibold  my-3">
                  {product.promoPrice === true
                    ? product.price
                    : (
                        parseFloat(product.actualprice) -
                        parseFloat(product.actualprice) *
                          (parseInt(product.discount) / 100)
                      ).toFixed(2) + " AED"}
                </p>
                {product.discount > 0 ? (
                  <del>
                    <p className="text-base font-poppins text-gray-600 ml-4">
                      {product.actualprice}
                      <p className="text-base font-poppins text-gray-600 ml-4"></p>
                    </p>
                  </del>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="flex items-center">
              <div className="flex flex-col gap-2 justify-stretch p-1 items-center">
                <Link
                  onClick={addProductToCart}
                  className="flex w-10 h-[35px] border-[1px] border-secondary rounded-md
                 hover:bg-secondaryHover hover:text-textWhite group-hover:border-cool-gray-200 items-center "
                >
                  <PlusIcon className="mx-auto text-[12px] w-6  items-center justify-center text-center" />
                </Link>
                <div
                  className="w-10 h-[35px] border-secondary mx-auto items-center justify-center align-middle 
                   inline-flex border-[0px] rounded-md"
                >
                  <span className="text-2xl font-bold">{product.quantity}</span>
                </div>

                {product.quantity > 0 ? (
                  <Link
                    onClick={removeProductToCart}
                    className="flex w-10 h-[35px] border-[1px] border-secondary group-hover:border-cool-gray-200
                      rounded-md hover:bg-secondaryHover hover:text-textWhite items-center"
                  >
                    <MinusIcon className="mx-auto text-[12px] w-6  items-center justify-center text-center" />
                  </Link>
                ) : (
                  <Link
                    className="flex w-10 h-[35px] border-[1px] border-secondary group-hover:border-cool-gray-200
                      rounded-md hover:bg-secondaryHover hover:text-textWhite items-center"
                  >
                    <MinusIcon className="mx-auto text-[12px] w-6  items-center justify-center text-center" />
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

export default ProductCard1;
