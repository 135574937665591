import React, { useContext } from "react";
import HomeProductCard from "./homePageProducts/HomeProductCard";
import { Link } from "react-router-dom";

import { ProductsContext } from "../context/products.context";

const MostPopularProducts = () => {
  //const { products } = useContext(ProductsContext);
  const { products } = useContext(ProductsContext);

  return (
    <div
      className="flex flex-col bg-primary px-4 gap-6 items-center justify-center justify-items-center 
      rounded-md"
    >
      <div>
        <h1 className="max-w-sm text-center mt-8 mb-2 text-6xl text-textWhite md:text-9xl md:max-w-2xl ">
          Our Most Popular
          <span className="text-6xl font-bold md:text-9xl"> Products </span>
        </h1>
      </div>

      <div className="flex items-start justify-center md:mb-5 scale-[.85] md:scale-100">
        <HomeProductCard products={products} />
      </div>
      <Link
        to="/productsrange"
        onClick={() => {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }}
        className="rounded-xl flex items-center justify-center max-w-4xl mb-8 w-full mt-2 border-[1px] border-cool-gray-400 text-textWhite font-poppins text-xl
         bg-secondary hover:bg-secondaryHover py-2 px-4"
      >
        {" "}
        Visit Zulal Store{" "}
      </Link>
    </div>
  );
};

export default MostPopularProducts;
