import React from "react";

const WhyZulal = () => {
  return (
    <div>
      <section>
        <div className="mx-auto px-5 mt-1 font-poppins flex flex-col items-center text-center md:mt-1 md:px-20">
          {/* Left div item md:w-1/2 */}

          <div className="flex flex-col items-center text-center">
            <h1 className="max-w-lg py-5  text-primary text-6xl md:max-w-2xl md:text-9xl dark:text-textWhite">
              ZULAL -{" "}
              <span className="text-6xl font-bold md:text-9xl">
                {" "}
                A Trusted Name{" "}
              </span>{" "}
              in Water Industry
            </h1>
            <p className="max-w-lg font-normal text-xl text-justify text-darkTextColor md:max-w-5xl">
              Zulal was established under the vision of H.H. Sheikh Dr. Sultan
              Bin Mohammed Al Qasimi, Supreme Court Member, and Ruler of
              Sharjah, to ensure access to clean pure drinking water for all
              residents of Sharjah. Founded in 1995, Zulal became the first
              government entity addressing this necessity. It remains the sole
              water company offering 100% pure underground water sourced
              directly from the Wells of Sharjah.
            </p>
          </div>

          <div className="flex flex-col items-center text-center mt-10">
            <h2 className="py-5 text-6xl text-primary md:text-9xl mb-10 dark:text-textWhite">
              Why
              <span className="font-bold"> Choose Zulal?</span>
            </h2>

            <div className="grid gap-6 mb-12 sm:grid-cols-2 lg:grid-cols-4">
              <div class="relative w-64 h-64 bg-secondary rounded-full flex justify-center items-center text-center p-5 shadow-xl">
                <div class="absolute w-56 h-56  border-2 border-dashed border-secondary bg-primaryVeryLight rounded-full flex justify-center items-center text-center">
                  <span class="absolute left-25 top-10 text-primary">
                    <img src="/images/drophand.svg" alt="" />
                  </span>
                  <span class="font-bold text-xl mt-16 text-primary">
                    Maximum Purity
                  </span>
                </div>
              </div>
              <div class="relative w-64 h-64 bg-secondary rounded-full flex justify-center items-center text-center p-5 shadow-xl">
                <div class="absolute w-56 h-56  border-2 border-dashed border-secondary bg-primaryVeryLight rounded-full flex justify-center items-center text-center">
                  <span class="absolute left-25 top-10 text-primary">
                    <img src="/images/glasswater.svg" alt="" />
                  </span>
                  <span class="font-bold text-xl mt-16  text-primary">
                    Low Sodium
                  </span>
                </div>
              </div>
              <div class="relative w-64 h-64 bg-secondary rounded-full flex justify-center items-center text-center p-5 shadow-xl">
                <div class="absolute w-56 h-56  border-2 border-dashed border-secondary bg-primaryVeryLight rounded-full flex justify-center items-center text-center">
                  <span class="absolute left-25 top-10 text-primary">
                    <img src="/images/spiralwater.svg" alt="" />
                  </span>
                  <span class="font-bold text-xl mt-16  text-primary">
                    High Filtration Standards
                  </span>
                </div>
              </div>
              <div class="relative w-64 h-64 bg-secondary rounded-full flex justify-center items-center text-center p-5 shadow-xl">
                <div class="absolute w-56 h-56  border-2 border-dashed border-secondary bg-primaryVeryLight rounded-full flex justify-center items-center text-center">
                  <span class="absolute left-25 top-10 text-primary">
                    <img src="/images/handglass.svg" alt="" />
                  </span>
                  <span class="font-bold text-xl mt-16  text-primary">
                    Fastest Delivery
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WhyZulal;
