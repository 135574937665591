import {
  ArrowDownIcon,
  ArrowUpIcon,
  PlusIcon,
} from "@heroicons/react/20/solid";
import {
  PlusCircleIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/24/outline";

import { UilShoppingCart, UilBill } from "@iconscout/react-unicons";

import { Link } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const DashboardInfo = (props) => {
  const stats = [
    {
      id: 1,
      name: "Last Order",
      icon: UilShoppingCart,
      change: "",
      changeType:
        parseInt(props.lastOrderAmount) <= 10.0 ? "decrease" : "increase",
      path: "../orders",
      stat: props.lastOrderAmount.toLocaleString("en-ae", {
        style: "currency",
        currency: "AED",
        minimumFractionDigits: 2,
      }),
      pageTitle: "Order(s) History",
      active: true,
    },
    {
      id: 2,
      name: "Last Invoice",
      stat: props.lastInvoiceAmount.toLocaleString("en-ae", {
        style: "currency",
        currency: "AED",
        minimumFractionDigits: 2,
      }),
      icon: UilBill,
      change: "",
      changeType:
        parseInt(props.lastInvoiceAmount) <= 10.0 ? "decrease" : "increase",
      path: "../invoices",
      state: "",
      pageTitle: "Invoice(s) History",
      active: true,
    },
    {
      id: 3,
      name: "E-Wallet History",
      stat: props.iCashBalance.toLocaleString("en-ae", {
        style: "currency",
        currency: "AED",
        minimumFractionDigits: 2,
      }),
      icon: CurrencyDollarIcon,
      change: "",
      changeType:
        parseInt(props.iCashBalance) <= 25.0 ? "decrease" : "increase", //increase
      path: "../zulalwallet",
      state: "",
      pageTitle: "Zulal Wallet",
      active: false,
    },
    {
      id: 4,
      name: "Top-Up",
      stat: "",
      icon: PlusCircleIcon,
      change: "",
      changeType: "increase",
      path: "../zulalwallet",
      state: "",
      pageTitle: "Zulal Credits",
      active: false,
    },
  ];

  return (
    <div>
      <div className="mx-auto">
        <dl className="mt-5 grid grid-cols-1 md:w-[550px] gap-5 sm:grid-cols-2 ">
          {stats.map(
            (item) =>
              item.active && (
                <div
                  key={item.id}
                  className="relative rounded-lg overflow-hidden bg-white px-4 pb-12 pt-5 shadow sm:px-4 sm:pt-6"
                >
                  <dt>
                    <div className="absolute rounded-md bg-secondary p-3">
                      <item.icon
                        className="h-6 w-6 text-textWhite"
                        aria-hidden="true"
                      />
                    </div>
                    <p className="ml-16 truncate text-sm font-medium text-gray-500">
                      {item.name}
                    </p>
                  </dt>
                  <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                    <div>
                      <p className="text-2xl font-semibold text-gray-900">
                        {item.stat}
                      </p>
                      <p
                        className={classNames(
                          item.changeType === "increase"
                            ? "text-green-600"
                            : "text-red-600",
                          "ml-2 flex items-baseline text-sm font-semibold"
                        )}
                      >
                        {item.changeType === "increase" ? (
                          <ArrowUpIcon
                            className="h-5 w-5 flex-shrink-0 self-center text-textGreen"
                            aria-hidden="true"
                          />
                        ) : (
                          <ArrowDownIcon
                            className="h-5 w-5 flex-shrink-0 self-center text-textRed"
                            aria-hidden="true"
                          />
                        )}

                        <span className="sr-only">
                          {" "}
                          {item.changeType === "increase"
                            ? "Increased"
                            : "Decreased"}{" "}
                          by{" "}
                        </span>
                        {item.change}
                      </p>
                    </div>
                    <div className="absolute inset-x-0 bottom-0 bg-primaryVeryLight px-4 py-4 sm:px-6">
                      <div className="text-sm">
                        <Link
                          to={item.path}
                          state={{ PageTitle: item.pageTitle }}
                          className="text-navarbarText hover:text-primaryHover flex flex-row gap-2 text-base font-poppins font-medium"
                        >
                          {item.name}
                        </Link>
                      </div>
                    </div>
                  </dd>
                </div>
              )
          )}
        </dl>
      </div>
    </div>
  );
};
