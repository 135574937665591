import React from "react";
import { useState } from "react";
import "./FormInouts.css";

export const FormInputs = (props) => {
  const [focus, setFocus] = useState(false);
  const { label, onChange, errorMessage, id, ...inputProps } = props;

  const handleFocused = (e) => {
    setFocus(true);
  };

  return (
    <div>
      <label>{label}</label>
      <input
        {...inputProps}
        autocomplete="off"
        onChange={onChange}
        className="border-0 ring-1 ring-secondary rounded-none rounded-r-md w-full 
         invalid:border-red-500 invalid:text-red-600 focus:invalid:border-red-500 focus:invalid:ring-red-500"
      />
      <formspan className="text-red-500 text-C12 font-poppins text-left mt-1">
        {errorMessage}
      </formspan>
    </div>
  );
};
