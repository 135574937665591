import React, { useContext, useEffect } from "react";
import SwiperTest from "../../components/homePageProducts/SwiperTest";

import { ProductsContext } from "../../context/products.context";

export const HomePageProducts = () => {
  const { products } = useContext(ProductsContext);

  return (
    <React.Fragment>
      <div
        className="mx-auto flex flex-col h-screen px-4 md:max-w-screen-lg gap-6 
                      bg-productsBackground rounded-md bg-cover
                     items-center justify-center justify-items-center"
      >
        <div>
          <h1 className="max-w-sm text-center mt-20 mb-2 text-6xl text-textWhite md:text-9xl md:max-w-2xl ">
            Our Premium Water
            <span className="text-6xl font-bold md:text-9xl"> Products </span>
          </h1>
        </div>
      </div>

      <SwiperTest products={products} />
    </React.Fragment>
  );
};
