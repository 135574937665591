import React from "react";
import NewsLetterSignup from "../../components/reuseableComp/NewsLetterSignup";
import CustomizeFooter from "../../components/reuseableComp/CustomizeFooter";

const Footer = () => {
  return (
    <div className="flex flex-col mx-auto max-w-full items-center mt-auto justify-center bg-primaryVeryLight">
      <div className="relative ">
        <div
          hidden
          className="absolute -top-40 md:-top-20 mx-auto w-full max-w-5xl"
        >
          <NewsLetterSignup />
        </div>
        <div className="mt-2 md:mt-20">
          <CustomizeFooter />
        </div>
      </div>
      <div className="flex mx-auto items-center justify-center w-full bg-pagePattern bg-center">
        <div className="flex flex-col md:flex-row mx-auto max-w-xs sm:max-w-xl md:max-w-5xl text-center items-center justify-between m-8 h-full bg-primary gap-4">
          <p className="text-base mb-2 w-[220px] sm:w-auto text-textWhite font-poppins ">
            Copyright © 2024 Zulal Water Company. All rights reserved.
          </p>
          <div className="flex flex-row gap-4">
            <span className="bg-primaryVeryLight w-16 h-12 rounded-md flex items-center align-middle justify-center">
              <img src="../../images/visa.png" alt="" className="w-10" />
            </span>

            <span className="bg-primaryVeryLight w-16 h-12 rounded-md flex items-center align-middle justify-center">
              <img src="../../images/mastercard.png" alt="" className="w-10" />
            </span>
            <span className="bg-primaryVeryLight w-16 h-12 rounded-md flex items-center align-middle justify-center">
              <img src="../../images/applepay1.png" alt="" className="w-10" />
            </span>
            <span className="bg-primaryVeryLight w-24 h-12 rounded-md flex items-center align-middle justify-center">
              <img src="../../images/samsungpay1.png" alt="" className="h-10" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
