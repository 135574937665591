import React, { useEffect, useState } from "react";

const OrderDetailsInfo = ({ orderInfo, customerAdderss }) => {
  const [orderMessageTitle, setOrderMessageTitle] = useState("");
  const [orderMessage, setOrderMessage] = useState("");
  const [orderStatus, setOrderStatus] = useState("");

  function getOrderNumber(orderInfo) {
    if (orderInfo) {
      if (orderInfo[0].orderNumber.length === 36) {
        return orderInfo[0].orderNumber.split("-")[4];
      } else {
        return orderInfo[0].orderNumber;
      }
    }
  }

  useEffect(() => {
    switch (orderInfo[0].status) {
      case "Scheduled":
        setOrderMessageTitle("Thank you!");
        setOrderMessage("It's on the way!");
        setOrderStatus("Scheduled");
        break;
      case "Completed":
        setOrderMessageTitle("Thank you!");
        setOrderMessage("It's Delivered ");
        setOrderStatus("Completed");
        break;
      case "Cancelled":
        setOrderMessageTitle("We are sorry :( ");
        setOrderMessage("It's Cancelled");
        setOrderStatus("Cancelled");
        break;
      case "Pending":
        setOrderMessageTitle("Thank you!");
        setOrderMessage("We already received");
        setOrderStatus("Pending");
        break;
      default:
        break;
    }
  }, []);

  return (
    <div className="bg-textWhite">
      <div className="mx-auto max-w-3xl px-4 py-4 sm:px-6 sm:py-8 lg:px-8">
        <div className="max-w-xl">
          <h1 className="text-base font-medium text-indigo-600">
            {orderMessageTitle}
          </h1>
          <p className="mt-2 text-4xl font-bold tracking-tight sm:text-5xl">
            {orderMessage}
          </p>
          <p className="mt-2 text-base text-gray-500">
            Your order #{getOrderNumber(orderInfo)} has been {orderStatus} on{" "}
            {orderInfo[0].scheduleDate}.
          </p>

          <dl className="mt-12 text-sm font-medium">
            <dt className="text-gray-900">Tracking number</dt>
            <dd className="mt-2 text-indigo-600">{orderInfo[0].orderNumber}</dd>
          </dl>
        </div>

        <div className="mt-10 border-t border-gray-200">
          <h2 className="sr-only">Your order</h2>

          <h3 className="sr-only">Items</h3>
          {orderInfo[0].orderItems.map((product) => (
            <div
              key={product.itemCode}
              className="flex space-x-6 border-b border-gray-200 py-10"
            >
              <img
                src={product.imageSrc}
                alt={product.imageAlt}
                className="h-20 w-20 flex-none rounded-lg bg-gray-100 object-cover object-center sm:h-40 sm:w-40"
              />
              <div className="flex flex-auto flex-col">
                <div>
                  <h4 className="font-medium text-gray-900">
                    <a href={product.href}>{product.itemName}</a>
                  </h4>
                  <p className="mt-2 text-sm text-gray-600">
                    {product.description}
                  </p>
                </div>
                <div className="mt-6 flex flex-1 items-end">
                  <dl className="flex space-x-4 divide-x divide-gray-200 text-sm sm:space-x-6">
                    <div className="flex">
                      <dt className="font-medium text-gray-900">Quantity</dt>
                      <dd className="ml-2 text-gray-700">{product.quantity}</dd>
                    </div>
                    <div className="flex pl-4 sm:pl-6">
                      <dt className="font-medium text-gray-900">Price</dt>
                      <dd className="ml-2 text-gray-700">{product.price}</dd>
                    </div>
                    <div className="flex pl-4 sm:pl-6">
                      <dt className="font-medium text-gray-900">UOM</dt>
                      <dd className="ml-2 text-gray-700">{product.uom}</dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          ))}

          <div className="sm:pl-2">
            <h3 className="sr-only">Your information</h3>

            <h4 className="sr-only">Addresses</h4>
            <dl className="grid grid-cols-3 gap-x-6 py-2 text-sm">
              <div>
                <dt className="font-medium text-gray-900">Shipping address</dt>
                <dd className="mt-2 text-gray-700">
                  <address className="not-italic max-w-xs">
                    <span className="block">{customerAdderss}</span>
                  </address>
                </dd>
              </div>
              <div>
                <dt className="font-medium text-gray-900">Shipping method</dt>
                <dd className="mt-2 text-gray-700">
                  <p>Zulal Transport</p>
                  <p>Takes up to 1 working days</p>
                </dd>
              </div>
              <div>
                <dt className="font-medium text-gray-900">Payment method</dt>
                <dd className="mt-2 text-gray-700">
                  <p>COD</p>
                  <p>SOFT POS</p>
                  <p>ICash/Wallet</p>
                </dd>
              </div>
            </dl>

            <h3 className="sr-only">Summary</h3>

            <dl className="space-y-2 border-t border-gray-200 pt-10 text-sm">
              <div className="flex justify-between">
                <dt className="font-medium text-gray-900">Subtotal</dt>
                <dd className="text-gray-700">
                  {orderInfo[0].grossTotal.toLocaleString("en-ae", {
                    style: "currency",
                    currency: "AED",
                    minimumFractionDigits: 2,
                  })}
                </dd>
              </div>
              <div className="flex justify-between">
                <dt className="flex font-medium text-gray-900">Discount</dt>
                <dd className="text-gray-700">
                  -
                  {orderInfo[0].totalDiscount.toLocaleString("en-ae", {
                    style: "currency",
                    currency: "AED",
                    minimumFractionDigits: 2,
                  })}
                </dd>
              </div>
              <div className="flex justify-between">
                <dt className="font-medium text-gray-900">Shipping</dt>
                <dd className="text-gray-700">Free</dd>
              </div>
              <div className="flex justify-between">
                <dt className="font-medium text-gray-900">Tax</dt>
                <dd className="text-gray-700">
                  {orderInfo[0].totalTax.toLocaleString("en-ae", {
                    style: "currency",
                    currency: "AED",
                    minimumFractionDigits: 2,
                  })}
                </dd>
              </div>
              <div className="flex justify-between">
                <dt className="font-medium text-gray-900">Total</dt>
                <dd className="text-gray-900">
                  {orderInfo[0].netTotal.toLocaleString("en-ae", {
                    style: "currency",
                    currency: "AED",
                    minimumFractionDigits: 2,
                  })}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetailsInfo;
