import React from "react";
import NewsAndMediaCards from "../../components/newsandmedia/NewsAndMediaCards";

import news from "../../utils/Data/NewAndMediaData.json";

const NewsAndMedia = () => {
  return (
    <React.Fragment>
      <div
        className="flex flex-col mx-auto bg-primary 
                   items-center justify-items-center justify-center 
                   mt-8 py-16"
      >
        <div>
          <span className="text-6xl text-textWhite md:text-9xl dark:text-white">
            <span className="font-bold"> News </span>&
            <span className="font-bold"> Media </span>
          </span>
        </div>

        {/* News and Media Card */}
        <NewsAndMediaCards news={news} />
      </div>
    </React.Fragment>
  );
};

export default NewsAndMedia;
