import React from "react";

import { create } from "./CreditCardPageInterface";

const handleSubmit = async (e) => {
  e.preventDefault();
  try {
    // const response = await window.NI.generateSessionId();
    // const sessionId = response.session_id;
    // console.log(sessionId);
    create();
    console.log();
  } catch (err) {
    console.error(err);
  }
};

const CreditCardPage = () => {
  return (
    <div>
      CreditCardPage
      <div id="mount-id"></div>
      <button
        id="buttonSubmit2"
        name="buttonSubmit2"
        onClick={handleSubmit}
        className="flex mt-4 mx-auto bg-secondary h-14 rounded-md w-auto text-white
       font-poppins text-base hover:bg-secondaryHover"
      >
        Search Customer
      </button>
    </div>
  );
};

export default CreditCardPage;
