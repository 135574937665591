import React, { useContext, useEffect } from "react";
import { RadioGroup } from "@headlessui/react";
import { useState } from "react";
import { UserContext } from "../../context/user.context";
import { NetworkInt } from "../../utils/NetworkInt";
import { useNavigate, useSearchParams } from "react-router-dom";

import axios from "axios";
import { CallCenterAPI } from "../../utils/callcenterAPI";

import data from "../../utils/Data/GeoLocations.json";
// import { saveTopUpOrderValue } from "../../utils/firebase/zulalfirebasedb";

const settings = [
  {
    id: 2,
    name: "Top-Up of 200",
    description: "would you like to Top-Up of 200?",
    value: 200,
  },
  {
    id: 3,
    name: "Top-Up of 300",
    description: "would you like to Top-Up of 200?",
    value: 300,
  },
  {
    id: 4,
    name: "Top-Up of 400",
    description: "would you like to Top-Up of 200?",
    value: 400,
  },
  {
    id: 5,
    name: "Top-Up of 500",
    description: "would you like to Top-Up of 200?",
    value: 500,
  },
];

const UserOrder = [
  {
    action: "PURCHASE",
    amount: {
      currencyCode: "AED",
      value: 1000,
    },
    emailAddress: "waseem.tahir@gmail.com",
    language: "en",
    merchantAttributes: {
      redirectUrl: "https://zulal.ae/zulalcredits",
      skipConfirmationPage: "true",
      merchantOrderReference: "WO-00000001",
    },
    billingAddress: {
      firstName: "Muhammad",
      lastName: "Waseem Tahir",
      address1: "Al Aroba Street 123",
      city: "Sharjah",
      countryCode: "UAE",
    },
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const ZulalCredits = () => {
  const amount = Math.round(Math.random() * 100, 2);
  const [selected, setSelected] = useState(settings[0]);
  const [userOrderValues, setuserOrderValues] = useState(UserOrder);
  const { currentUser, sonicAccessToken } = useContext(UserContext);

  const [searchParams, setSearchParams] = useSearchParams();

  const customerToken = sonicAccessToken;
  const params = { customerToken: customerToken };

  let [userDetails, setUserDetails] = useState({});

  const [userCityName, setUserCityName] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [iCashBalance, setICashBalance] = useState(-1);

  useEffect(() => {
    if (customerToken !== null) {
      const params = { customerToken: customerToken };
      getCustomerDetails(params);
      getCustomerICash(params);
    }
  }, [customerToken]);

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  function generateUniqueOrder() {
    return Math.floor(Math.random() * Date.now()).toString(16);
  }

  const getCustomerDetails = async (params) => {
    const objCustomerDetails = CallCenterAPI().getCustomerDetails(params);
    objCustomerDetails
      .then((detailres) => {
        userDetails = structuredClone(detailres.data);

        console.log("Data loaded from Zulal Credits ");
        console.log(userDetails);

        setUserAddress(userDetails.addresses[0].address);

        const foundCity = data.cities.find(
          (city) =>
            city.CityID === userDetails.cityID &&
            city.StateID === userDetails.stateID
        );

        // const foundCity = data.cities.find(
        //   (city) => city.CityID == userDetails.cityID && city.StateID == 1
        // );

        if (foundCity) {
          setUserCityName(foundCity.CityName);
        } else {
          console.log("city not found");
          setUserCityName("NA");
        }

        console.log(foundCity);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  function getCustomerICash(params) {
    const customerICash = CallCenterAPI().getCustomerICash(params);

    console.log(customerICash);

    customerICash
      .then((response) => {
        const money = response.data.balance;
        setICashBalance(money);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  const handlSubmit = (e) => {
    e.preventDefault();
    console.log(`name: ${selected.name} + amount: ${selected.value}`);

    alert("We will redirect you to Payment Gateway Page");

    const userOrder = {
      action: "PURCHASE",
      amount: {
        currencyCode: "AED",
        value: selected.value * 100,
      },
      emailAddress: currentUser.email,
      language: "en",
      merchantAttributes: {
        redirectUrl: "https://qa.zulal.ae:3000/topupconfirmation",
        skipConfirmationPage: "true",
        merchantOrderReference: currentUser.customerCode,
      },
      billingAddress: {
        firstName: currentUser.outletName.split(" ")[0],
        lastName: currentUser.outletName.split(" ")[1],
        address1: userAddress,
        city: userCityName,
        countryCode: "UAE",
      },
    };

    // axios
    //   .get(React_App_NI_Auth_URL)
    //   .then((response) => {
    //     console.log(response);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    const tokenResponse = NetworkInt().getAccessToken();
    //console.log(tokenResponse)
    tokenResponse
      .then((response) => {
        //    console.log(response.access_token);
        const accessToken = response.access_token;

        // console.log(accessToken);
        console.log(userOrderValues);

        const params = { accessToken: accessToken, userOrder: userOrder };

        const orderResponse = NetworkInt().createOrder(params);

        orderResponse
          .then((response) => {
            console.log(response.data);
            console.log(response.data.payByLinkUrl);
            const amount = selected.value;

            // saveTopUpOrderValue(
            //   currentUser.customerCode,
            //   response.data.orderReference,
            //   {
            //     Amount: amount,
            //     Status: "STARTED",
            //   }
            // )
            //   .then((res) => {
            //     console.log("Save TopUpOrder in Firebase");
            //   })
            //   .catch((err) => {
            //     console.log("Got Error by saving TopUpOrder in Firebase");
            //   });

            openInNewTab(response.data.payByLinkUrl);
          })
          .catch((err) => {
            console.log("Error While Saving Order In Network Internation API");
            console.log(err);
          });
      })
      .catch((err) => {
        console.log("Unable to get Token from Network Internation API");
        console.log(err);
      });
  };

  return (
    <div>
      <form action="#" method="POST">
        <div className="mx-auto max-w-2xl mr-4 ml-4 mb-28">
          <div className="text-navarbarText text-3xl font-poppins font-medium mb-8">
            Zulal Credits
            <p className="text-base text-darkTextColor font-poppins font-normal">
              Please select the amount you need to recharge. Your current
              balance is <b> {iCashBalance} AED</b>. <br /> The below given
              amounts are without TAX.
            </p>
          </div>
          <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
            <RadioGroup value={selected} onChange={setSelected}>
              <RadioGroup.Label className="sr-only">
                Privacy setting
              </RadioGroup.Label>
              <div className="-space-y-px rounded-md bg-white">
                {settings.map((setting, settingIdx) => (
                  <RadioGroup.Option
                    key={setting.name}
                    value={setting}
                    className={({ checked }) =>
                      classNames(
                        settingIdx === 0 ? "rounded-tl-md rounded-tr-md" : "",
                        settingIdx === settings.length - 1
                          ? "rounded-bl-md rounded-br-md"
                          : "",
                        checked
                          ? "z-10 border-indigo-200 bg-indigo-50"
                          : "border-gray-200",
                        "relative flex cursor-pointer border p-4 focus:outline-none"
                      )
                    }
                  >
                    {({ active, checked }) => (
                      <>
                        <span
                          className={classNames(
                            checked
                              ? "bg-indigo-600 border-transparent"
                              : "bg-white border-gray-300",
                            active
                              ? "ring-2 ring-offset-2 ring-indigo-600"
                              : "",
                            "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                          )}
                          aria-hidden="true"
                        >
                          <span className="rounded-full bg-white w-1.5 h-1.5" />
                        </span>
                        <span className="ml-3 flex flex-col">
                          <RadioGroup.Label
                            as="span"
                            className={classNames(
                              checked ? "text-indigo-900" : "text-gray-900",
                              "block text-sm font-medium"
                            )}
                          >
                            {setting.name}
                          </RadioGroup.Label>
                          <RadioGroup.Description
                            as="span"
                            className={classNames(
                              checked ? "text-indigo-700" : "text-gray-500",
                              "block text-sm"
                            )}
                          >
                            {setting.description}
                          </RadioGroup.Description>
                        </span>
                      </>
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          </div>
          <div className="mt-8 flex justify-end">
            <button
              type="submit"
              onClick={handlSubmit}
              className="rounded-md bg-secondary  px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-secondaryHover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Load the Top-Up
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
