import React from "react";

const DidYouKnow = () => {
  return (
    <div
      className="mx-auto max-w-sm rounded-lg bg-primary items-center justify-center 
                     mb-24 px-10 py-16 gap-8 flex flex-col md:flex-row md:max-w-5xl"
    >
      <img src="/images/thinking.svg" alt="" />
      <div className="text-base text-textWhite">
        Zulal has actively supported the community by supplying free drinking
        water to Sharjah schools since 2014, directed by H.H. Sheikh Dr. Sultan
        Bin Mohammed Al Qasimi, Supreme Court Member, and Ruler of Sharjah.
      </div>
    </div>
  );
};

export default DidYouKnow;
