import React from "react";
import { Breadcrumbs } from "../components/navbar/Breadcrumbs";

import { ReactComponent as Image404 } from "../images/404.svg";
import { NavLink } from "react-router-dom";

export const Page404 = () => {
  return (
    <div>
      <div className="bg-pagePattern bg-center">
        <div className="flex mx-auto max-w-screen-lg text-center justify-center items-center mb-9 h-32  ">
          <span className="text-7xl text-white font-medium  ">
            Page Not Found
          </span>
        </div>
      </div>

      <div className="ml-4">
        <Breadcrumbs />
      </div>

      <div className="flex flex-col mx-auto max-w-screen-lg text-center justify-center items-center">
        <div>
          <Image404 />
        </div>
        <div className="text-red-500 text-xl font-normal">
          ERROR 404 - PAGE NOT FOUND
        </div>
        <div className="text-zinc-500 text-base font-normal uppercase tracking-widest">
          the page you requested could not be Found
        </div>

        <div className="text-primary text-2xl mt-16 mb-10 font-medium">
          <NavLink to="/">Go Back Home</NavLink>
        </div>
      </div>
    </div>
  );
};
