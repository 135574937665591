import React, { useEffect } from "react";

import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { UilShoppingCart } from "@iconscout/react-unicons";

import { OrdersAPI } from "../../utils/OrdersAPI";

// const products = [
//   {
//     id: 1,
//     name: "Basic Tee",
//     href: "#",
//     price: "$36.00",
//     color: "Charcoal",
//     size: "L",
//     imageSrc:
//       "https://tailwindui.com/plus/img/ecommerce-images/confirmation-page-06-product-01.jpg",
//     imageAlt: "Model wearing men's charcoal basic tee in large.",
//   },
//   {
//     id: 2,
//     name: "Basic Tee",
//     href: "#",
//     price: "$36.00",
//     color: "Charcoal",
//     size: "L",
//     imageSrc:
//       "https://tailwindui.com/plus/img/ecommerce-images/confirmation-page-06-product-01.jpg",
//     imageAlt: "Model wearing men's charcoal basic tee in large.",
//   },
//   {
//     id: 3,
//     name: "Basic Tee",
//     href: "#",
//     price: "$36.00",
//     color: "Charcoal",
//     size: "L",
//     imageSrc:
//       "https://tailwindui.com/plus/img/ecommerce-images/confirmation-page-06-product-01.jpg",
//     imageAlt: "Model wearing men's charcoal basic tee in large.",
//   },
//   {
//     id: 4,
//     name: "Basic Tee",
//     href: "#",
//     price: "$36.00",
//     color: "Charcoal",
//     size: "L",
//     imageSrc:
//       "https://tailwindui.com/plus/img/ecommerce-images/confirmation-page-06-product-01.jpg",
//     imageAlt: "Model wearing men's charcoal basic tee in large.",
//   },

//   // More products...
// ];

const OrderTracking = () => {
  //query parameters
  const queryParams = new URLSearchParams(window.location.search);
  const orderId = queryParams.get("ref");
  const [orderDetails, setOrderDetails] = useState(null);
  const [products, setProducts] = useState(null);

  console.log(`first order ${orderId}`);

  function getLastNCharacters(str, n) {
    const retString = str.substr(Math.max(str.length - n, 0));
    return retString.toUpperCase();
  }

  const [customerAddress, setCustomerAddress] = useState({});
  // let CustomerAddress = {
  //   BuildingName: "",
  //   FlatNumber: "",
  //   LandMark: "",
  //   Address: "",
  // };

  useEffect(() => {
    //const orderIdObject = { ref: orderId };
    const orderIdObject = { ref: orderId };

    const fetchData = async () => {
      // get the data from the api
      console.log(`order Object: ${orderIdObject}`);

      const orderDetails = await OrdersAPI().orderTrackingInfo(orderIdObject);
      // convert the data to json
      console.log(orderDetails.data.BuildingName);
      // set state with the result
      setOrderDetails(orderDetails);

      //console.log(orderDetails.paymentState);
      //setting up the Products from the order

      setCustomerAddress({
        buildingName: orderDetails.data.BuildingName,
        flatNumber: orderDetails.data.FlatNumber,
        landmark: orderDetails.data.Landmark,
        address: orderDetails.data.CustomerAddress,
      });

      console.log(orderDetails.data.allItems);
      setProducts(orderDetails.data.allItems);

      // Check if the payment was successful
      if (orderDetails.paymentState === true) {
        setPaymentStatus("Payment Successfully Processed");
      } else {
        setPaymentStatus("Payment Failed");
      }
    };

    fetchData();
  }, [orderId]);

  const [showDialog, setShowDialog] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("Pending");

  return (
    <>
      <div className="bg-pagePattern bg-center">
        <div className="flex mx-auto max-w-screen-lg text-center justify-center items-center md:mb-9 h-16 md:h-32">
          <span className="text-2xl text-textWhite font-medium md:text-5xl lg:text-7xl  ">
            Order Tracking
          </span>
        </div>
      </div>

      <main className="relative lg:min-h-full">
        <div className="h-80 overflow-hidden lg:absolute lg:h-full lg:w-1/2 lg:pr-4 xl:pr-12">
          <img
            alt="TODO"
            src="../../images/zulalTrackingOrder.png"
            className="object-contain object-top"
          />
        </div>

        <div>
          <div className="mx-auto max-w-2xl px-4 py-4 sm:px-6 sm:py-6 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:py-4 xl:gap-x-24">
            <div className="lg:col-start-2">
              <h1 className="text-sm font-medium text-green-600">
                {paymentStatus}
              </h1>
              <p className="mt-4 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                Thanks for ordering
              </p>
              <p className="mt-4 text-base text-gray-500">
                We’ve received your order and are already preparing it for
                shipment. Thank you for choosing Zulal! Your trust means a lot
                to us, and we’re committed to providing you with quality
                products and services. <br />
                If you have any questions or need support, please don’t hesitate
                to reach out.
              </p>

              <dl className="mt-8 text-sm font-medium">
                <dt className="text-gray-900">
                  Order Number number : {getLastNCharacters(orderId, 6)}
                </dt>
                <dd className="mt-2 text-indigo-600">{}</dd>
              </dl>

              <ul
                role="list"
                className="mt-6 divide-y divide-gray-200 border-t border-gray-200 text-sm font-medium text-gray-500"
              >
                {products &&
                  products.map((product) => (
                    <li key={product.itemCode} className="flex space-x-6 py-6">
                      <img
                        alt={""}
                        src={product.src}
                        className="h-24 w-24 flex-none rounded-md bg-gray-100 object-cover object-center"
                      />
                      <div className="flex-auto space-y-1">
                        <h3 className="text-gray-900">
                          <a href={product.href}>{product.name}</a>
                        </h3>
                        <p>{product.uom}</p>
                      </div>
                      <p className="flex-none font-medium text-gray-900">
                        Quantity : {product.quantity} x Price : {product.price}
                      </p>
                    </li>
                  ))}
              </ul>

              <dl className="space-y-6 border-t border-gray-200 pt-6 text-sm font-medium text-gray-500">
                <div className="flex justify-between">
                  <dt>Subtotal</dt>
                  <dd className="text-gray-900">$72.00</dd>
                </div>

                <div className="flex justify-between">
                  <dt>Shipping</dt>
                  <dd className="text-gray-900">$8.00</dd>
                </div>

                <div className="flex justify-between">
                  <dt>Taxes</dt>
                  <dd className="text-gray-900">$6.40</dd>
                </div>

                <div className="flex items-center justify-between border-t border-gray-200 pt-6 text-gray-900">
                  <dt className="text-base">Total</dt>
                  <dd className="text-base">$86.40</dd>
                </div>
              </dl>

              <dl className="mt-16 grid grid-cols-2 gap-x-4 text-sm text-gray-600">
                <div>
                  <dt className="font-medium text-gray-900">
                    Shipping Address
                  </dt>
                  <dd className="mt-2">
                    <address className="not-italic">
                      <span className="block">
                        {customerAddress.flatNumber +
                          "," +
                          customerAddress.buildingName}
                      </span>
                      <span className="block">{customerAddress.landMark}</span>
                      <span className="block">{customerAddress.address}</span>
                    </address>
                  </dd>
                </div>
                <div>
                  <dt className="font-medium text-gray-900"></dt>
                  <dd className="mt-2 space-y-2 sm:flex sm:space-x-4 sm:space-y-0">
                    <div className="flex-none">
                      {/* SVG and VISA information removed */}
                    </div>
                    <div className="flex-auto">
                      <p className="text-gray-900"></p>
                      <p></p>
                    </div>
                  </dd>
                </div>
              </dl>

              <div className="mt-16 border-t border-gray-200 py-6 text-right">
                <a
                  href="https://www.zulal.ae/productsrange"
                  className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                >
                  Continue Shopping
                  <span aria-hidden="true"> &rarr;</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default OrderTracking;
