const style = {
  main: {} /* the style for the main wrapper div around the card input*/,
  base: {} /* this is the default styles that comes with it */,
  input: {} /* custom style options for the input fields */,
  invalid: {} /* custom input invalid styles */,
};
export const create = () => {
  const response = window.NI.generateSessionId();
  const sessionId = response.session_id;
  const hostedSessionAPIKey = "";
  const outletRef = process.env.React_App_ZULAL_OUTLET_KEY;
  const options = "There is some text";
  console.log(sessionId, options);
};
