import { createContext, useEffect, useState } from "react";

//as the actual value you want to access
// const [user, setUser] = useState({
//   userName: "",
//   customerCode: "",
//   phone: "",
//   email: "",
//   OTP: "",
// });

export const UserContext = createContext({
  currentUser: {
    outletName: "",
    customerCode: "",
    phoneNo: "",
    email: "",
    address: "",
    OTP: "",
    smsType: "",
    isloggedIn: false,
  },
  customerProfile: {
    customerName: "",
    customerCode: "",
    customerEmail: "",
    customerPhone: "",
    splGroupCode: "",
  },
  customerDetails: {
    landmark: "",
    bldgName: "",
    flatNumber: "",
    driverName: "",
    driverMobileNumber: "",
    splGroupCode: "",
    name: "",
    email: "",
    dob: "",
    countryID: 0,
    stateID: 0,
    cityID: 0,
    AreaId: 0,
    postCode: "",
    addresses: [
      {
        address: "",
        is_Default: true,
      },
    ],
    numbers: [
      {
        number: "",
        is_Default: false,
        countryId: 1,
      },
    ],
  },
  accessToken: null,
  sonicAccessToken: null,
  setCurrentUser: () => null,
  setCustomerProfile: () => null,
  setCustomerDetails: () => null,
  setAccessToken: () => null,
  setSonicAccessToken: () => null,
});

export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState("");
  const [customerProfile, setCustomerProfile] = useState("");
  const [customerDetails, setCustomerDetails] = useState("");
  const [accessToken, setAccessToken] = useState(null);
  const [sonicAccessToken, setSonicAccessToken] = useState(null);

  const value = {
    currentUser,
    setCurrentUser,
    customerProfile,
    setCustomerProfile,
    customerDetails,
    setCustomerDetails,
    accessToken,
    setAccessToken,
    sonicAccessToken,
    setSonicAccessToken,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
