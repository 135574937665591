import CryptoJS from "crypto-js";

const secretKeyBase64 =
  "U2VjcmV0S2V5U2VjcmV0S2V5U2VjcmV0S2V5U2VjcmV0S2V5U2VjcmV5";
const adminsecretKey = CryptoJS.enc.Base64.parse(secretKeyBase64);

export const encryptDataBase64 = (data, secretKey) => {
  // Encrypt data using AES
  const encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    secretKey
  ).toString();

  // Encode encrypted data to Base64
  const base64Encrypted = btoa(encrypted);

  return base64Encrypted;
};

export const encrypt = (text) => {
  return CryptoJS.AES.encrypt(
    text,
    "bPsKdaO786c8dH1Ibk1a7WIQD9m9lb+4OisDem6taZ8="
  ).toString();
};

export const MyEncrypt = (text) => {
  const encrypted = CryptoJS.AES.encrypt(text, adminsecretKey).toString();
  return encrypted;
};

// // Function to encrypt JSON data in React.js
// const encryptAndEncodeBase64 = (jsonData, secretKey) => {
//   const encrypted = CryptoJS.AES.encrypt(
//     JSON.stringify(jsonData),
//     secretKey
//   ).toString();
//   return encrypted;
// };
// export default encryptAndEncodeBase64;
