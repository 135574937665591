import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../context/user.context";

export const Logout = () => {
  const nav = useNavigate();
  const { setCurrentUser, setSonicAccessToken } = useContext(UserContext);

  function LogoutUser() {
    // setCurrentUser({
    //   customerCode: "",
    //   outletName: "",
    //   email: "",
    //   phoneNo: "",
    //   customerAddress: "",
    //   OTP: "",
    //   isloggedIn: false,
    // });

    sessionStorage.removeItem("sAccessToken");
    sessionStorage.removeItem("customerToken");
    setCurrentUser(null);
    setSonicAccessToken(null);
    setCurrentUser({ isloggedIn: false });

    nav("../../");
  }

  return (
    <div className="mx-auto mr-4 ml-4 mb-28">
      <div className="text-navarbarText text-3xl font-poppins font-medium mb-8">
        Lout out
        <p className="text-base text-darkTextColor font-poppins font-normal">
          Thank for choosing Zulal services.
        </p>
      </div>

      <div className="mt-8 flex justify-start">
        <button
          type="button"
          onClick={LogoutUser}
          className="rounded-md bg-secondary px-3.5 py-2.5 text-center text-sm font-semibold text-textWhite shadow-sm hover:bg-secondaryHover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Log Out
        </button>
      </div>
    </div>
  );
};
