import React from "react";
import { Breadcrumbs } from "../components/navbar/Breadcrumbs";
import { Link, NavLink, useLocation } from "react-router-dom";
import { ReactComponent as Image404 } from "../images/comingsoon.svg";

export const ComingSoon = () => {
  const location = useLocation();
  const { hash, pathname, search } = location;

  function comingsoonMessage(pathname) {
    let message = "";
    switch (pathname) {
      case "/NewsMedia":
        message = "News Media Page is comming soon.";
        break;
      case "/ComingSoon":
        message = "Zulal Wallet Page is comming soon.";
        break;
      default:
        break;
    }

    return message;
  }

  return (
    <div>
      <div className="bg-pagePattern bg-center">
        <div className="flex mx-auto max-w-screen-lg text-center justify-center items-center mb-8 h-32  ">
          <span className="text-7xl text-textWhite font-medium  ">
            STAY TUNED
          </span>
        </div>
      </div>

      <div className="flex flex-col mx-auto max-w-screen-lg text-center justify-center items-center">
        <div className="mt-4 mb-4">
          <Image404 />
        </div>
        <div className="text-red-500 text-base font-normal uppercase tracking-widest">
          STAY TUNED - {comingsoonMessage(pathname)}
        </div>

        <div className="text-primary text-2xl mt-16 mb-10 font-medium">
          <Link to="/">Go Back Home</Link>
        </div>
      </div>
    </div>
  );
};
