import axios from "axios";
import {
  NGen_AccessToken_API_URL,
  NGen_ORDERS_API_URL,
  NGen_GetOrder_API_URL,
} from "./config";

export const NetworkInt = () => {
  const env_var = process.env.NODE_ENV;
  const accessUrl = NGen_AccessToken_API_URL;
  const ordersURL = NGen_ORDERS_API_URL;
  const getOrderURL = NGen_GetOrder_API_URL;

  return {
    getAccessToken: (req, res) =>
      axios.get(accessUrl).then((response) => {
        if (env_var === "development") {
          console.log("Development mode enabled");
          console.log(response.data);
        }
        res = response.data;
        return res;
      }),
    createOrder: (req, res) =>
      axios
        .post(ordersURL, req)
        .then((response) => {
          if (env_var === "development") {
            console.log("Development mode enabled");
            console.log(response.data);
          }
          res = response.data;
          return res;
        })
        .catch((err) => {
          res = err.data;
          return res;
        }),
    getOrder: (req, res) =>
      axios
        .get(getOrderURL, { params: req })
        .then((response) => {
          if (env_var === "development") {
            console.log("Development mode enabled");
            console.log(response.data);
          }
          res = response.data;
          return res;
        })
        .catch((error) => {
          res = error.message;
          return res;
        }),
  };
};
