import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Mousewheel, Navigation, Pagination, Scrollbar } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import "./TestimonialsCards.css";

import { TestimonialsPrevButton } from "./TestimonialsPrevButton";
import { TestimonialsNextButton } from "./TestimonialsNextButton";

const TestimonialsCards = ({ testimonials }) => {
  return (
    <Swiper
      pagination={{
        clickable: true,
      }}
      slidesPerView={1}
      spaceBetween={30}
      className="max-w-sm sm:max-w-2xl md:max-w-xl lg:max-w-2xl  "
      modules={[Pagination, Navigation, Scrollbar, Mousewheel, A11y]}
    >
      {testimonials.map((item) => (
        <SwiperSlide key={item.id} className="mt-8  text-center">
          <div className="mx-auto text-base sm:max-w-sm text-center h-36 md:h-16 font-normal text-textgray">
            {item.message}
          </div>

          <div className="text-3xl font-bold mt-8 text-primary uppercase">
            {item.name}
          </div>
          <div className="text-C12 font-normal mt-2 text-textgray">
            {item.designation}
          </div>
        </SwiperSlide>
      ))}

      <div className="h-5"></div>
      <TestimonialsPrevButton />
      <TestimonialsNextButton />
      <div className="h-10"></div>
    </Swiper>
  );
};

export default TestimonialsCards;
