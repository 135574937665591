import React from "react";
import { useContext } from "react";
import { CartContext } from "../../context/cart.context";
import { Link } from "react-router-dom";

const HomePageCardItem = ({ product }) => {
  const { addItemToCart } = useContext(CartContext);

  const addProductToCart = () => {
    addItemToCart(product);
  };

  return (
    <div>
      <div className="mx-auto items-center justify-center text-cool-gray-600">
        {product.instock && product.discount > 1 && (
          <div
            className="absolute top-0 right-0 z-10 m-2 flex flex-col items-center align-middle justify-center rounded-full w-24 h-24
bg-primary border-2 border-dotted border-primaryLight outline-dashed outline-1 outline-primaryLight"
          >
            <span className="text-4xl text-textWhite font-poppins">
              {product.discount}%
            </span>
            <span className="text-textWhite font-poppins text-base">Off</span>
          </div>
        )}
        <div className="mx-auto flex flex-col align-middle items-center justify-center">
          <Link
            to="/productsrange"
            onClick={() => {
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
              });
            }}
          >
            <div>
              <img
                src={product.imageSrc}
                alt={product.imageAlt}
                className="h-40 drop-shadow-lg align-middle items-center justify-center"
              />
              <p className="flex mt-1 mb-2 font-semibold font-poppins text-2xl items-center justify-center">
                {product.name}
              </p>
            </div>
          </Link>

          <p className="flex mt-1 mb-2 font-poppins text-xl items-center justify-center">
            {product.description}
          </p>
        </div>

        {product.instock && product.discount > 1 ? (
          <div className="flex flex-row justify-between">
            <p className="flex mt-2 mb-7 font-poppins font-extrabold text-xl items-center justify-center">
              {(
                parseFloat(product.actualprice) -
                parseFloat(product.actualprice) *
                  (parseInt(product.discount) / 100)
              ).toFixed(2)}{" "}
              AED
            </p>
            <p className="flex mt-2 mb-7 font-poppins text-base items-center justify-center line-through">
              {product.actualprice}
            </p>
          </div>
        ) : (
          <div className="flex flex-row justify-between">
            <p className="flex mt-2 mb-7 font-poppins font-extrabold text-xl items-center justify-center">
              {product.actualprice}
            </p>
          </div>
        )}
      </div>
      <div className="hidden">
        <button
          type="button"
          onClick={addProductToCart}
          className="absolute flex rounded-full cursor-pointer bg-secondary 
      -bottom-[20px] right-[70px] font-poppins h-12 text-textWhite w-40 py-2 items-center justify-center"
        >
          Add to Cart
        </button>
      </div>
    </div>
  );
};

export default HomePageCardItem;
