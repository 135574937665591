import { createContext, useEffect, useState } from "react";

const addCartItem = (cartItems, productToAdd) => {
  const existingCartItems = cartItems.find(
    (item) => item.id === productToAdd.id
  );

  if (existingCartItems) {
    return cartItems.map((cartItem) => {
      if (cartItem.id === productToAdd.id) {
        const ncartItem = { ...cartItem, quantity: cartItem.quantity + 1 };
        productToAdd.quantity = productToAdd.quantity + 1;

        return ncartItem;
      } else {
        return cartItem;
      }
    });
  }

  productToAdd.quantity = productToAdd.quantity + 1;
  return [...cartItems, { ...productToAdd, quantity: 1 }];
};

const removeCartItem = (cartItems, productToRemove) => {
  const existingCartItems = cartItems.find(
    (item) => item.id === productToRemove.id
  );

  if (existingCartItems.quantity === 1) {
    productToRemove.quantity = 0;
    return cartItems.filter((item) => item.id !== productToRemove.id);
  }

  if (existingCartItems) {
    return cartItems.map((cartItem) => {
      if (cartItem.id === productToRemove.id) {
        //console.log("condition true");

        const ncartItem = { ...cartItem, quantity: cartItem.quantity - 1 };
        productToRemove.quantity = productToRemove.quantity - 1;

        return ncartItem;
      } else {
        //console.log(cartItem.id);
        return cartItem;
      }
    });
  }
};

const clearCartItem = (cartItems, productToRemove) => {
  // const existingCartItems = cartItems.find(
  //   (item) => item.id === productToRemove.id
  // );

  // if (existingCartItems) {
  //   console.log("condition true");
  //   productToRemove.quantity = 0;
  // }

  //console.log(productToRemove.id);
  //console.log(productToRemove.quantity);
  productToRemove.quantity = 0;

  return cartItems.filter((item) => item.id !== productToRemove.id);

  // const existingCartItems = cartItems.find(
  //   (item) => item.id === productToRemove.id
  // );

  // if (existingCartItems.quantity > 1) {
  //   productToRemove.quantity = 0;
  //   return cartItems.filter((item) => item.id !== productToRemove.id);
  // }
};

export const CartContext = createContext({
  isCartOpen: false,
  setIsCartOpen: () => {},
  cartItems: [],
  addItemToCart: () => {},
  removeItemToCart: () => {},
  clearItemfromCart: () => {},
  setCartItems: [],
  cartCount: 0,
  cartTotal: 0,
  shippingCharges: 0,
  cartGTotal: 0,
});

export const CartProivder = ({ children }) => {
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [cartCount, setCartCount] = useState(0);
  const [cartTotal, setCartTotal] = useState(0);
  const [shippingCharges, setShippingCharges] = useState(0);
  const [cartGTotal, setCartGTotal] = useState(0);

  useEffect(() => {
    const newCartCount = cartItems.reduce(
      (acc, item) => acc + item.quantity,
      0
    );

    setCartCount(newCartCount);
  }, [cartItems]);

  useEffect(() => {
    const newCartTotal = cartItems.reduce(
      (acc, item) => acc + parseInt(item.quantity) * parseFloat(item.price),
      0
    );

    let shCharges = 0;

    if (newCartTotal < 75) {
      //set shipping charges here
      setShippingCharges(0);
      shCharges = 0;
    } else {
      setShippingCharges(0);
      shCharges = 0;
    }

    const vatAmount = (parseFloat(newCartTotal) * parseFloat(0.05)).toFixed(2);

    const gTotal =
      parseFloat(newCartTotal) + parseInt(shCharges) + parseFloat(vatAmount);

    setCartGTotal(gTotal);
    setCartTotal(newCartTotal);
  }, [cartItems]);

  const addItemToCart = (productToAdd) => {
    setCartItems(addCartItem(cartItems, productToAdd));
  };

  const removeItemToCart = (productToRemove) => {
    setCartItems(removeCartItem(cartItems, productToRemove));
  };

  const clearItemfromCart = (productToRemove) => {
    setCartItems(clearCartItem(cartItems, productToRemove));
  };

  const value = {
    isCartOpen,
    setIsCartOpen,
    addItemToCart,
    removeItemToCart,
    clearItemfromCart,
    setCartItems,
    cartItems,
    cartCount,
    cartTotal,
    shippingCharges,
    cartGTotal,
  };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};
