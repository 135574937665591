import React, { useState } from "react";
import { Breadcrumbs } from "../../components/navbar/Breadcrumbs";
import { BuildingOffice2Icon } from "@heroicons/react/24/outline";

import { encryptDataBase64 } from "../../utils/encryptionUtils";
import generateSecretKey from "../../utils/keyGenerationUtils";

import {
  EnvelopeIcon,
  EnvelopeOpenIcon,
  MapPinIcon,
  PhoneArrowUpRightIcon,
  PhoneIcon,
} from "@heroicons/react/20/solid";
import { CallCenterAPI } from "../../utils/callcenterAPI";

export const Contactus = () => {
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    tel: "",
    message: "",
  });

  const [message, setMessage] = useState("");

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const secretKey = generateSecretKey();
    //console.log("Generated Secret Key (Frontend):", secretKey);
    const encryptedMyData = encryptDataBase64(values, secretKey);
    //console.log("Encrypted Data (Frontend):", encryptedMyData);
    //setEncryptedData(encryptedMyData);

    //const decryptedData1 = decryptDataBase64(encryptedMyData, secretKey);
    //console.log("Decrypted Data (Frontend):", decryptedData1);
    //setDecryptedData(decryptedData1);

    //console.log("after decrypting");

    const data = {
      encryptedData: encryptedMyData,
      secretKey: secretKey,
    };

    try {
      CallCenterAPI()
        .sendEmail(data)
        .then((response) => {
          console.log(response);
          setMessage(response);
        })
        .catch((error) => {
          setMessage(error);
        });
    } catch (error) {
      setMessage("server error : We are unable to send email");
    }

    // console.log(response.response.data);
    // if (response.response.status === 500) {
    //   setMessage("SERVER ERROR : We are unalbe to send email");
    // } else {
    //   setMessage("Email sent successfully !!!");
    // }

    // if (response.status === 500) {
    //   alert("We are unable to send email");
    // }
  };

  return (
    <div>
      <div className="bg-pagePattern bg-center">
        <div className="flex mx-auto max-w-screen-lg text-center justify-center items-center mb-9 h-32  ">
          <span className="text-7xl text-textWhite font-medium  ">
            Contact Us
          </span>
        </div>
      </div>

      <div className="relative isolate bg-white">
        <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
          <div className="relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-48">
            <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
              <div className="absolute inset-y-0 left-0 -z-10 w-full overflow-hidden bg-secondaryVeryLight ring-1 ring-blue-200 lg:w-1/2">
                <svg
                  className="absolute inset-0 h-full w-full stroke-secondaryHover [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                  aria-hidden="true"
                >
                  <defs>
                    <pattern
                      id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                      width={200}
                      height={200}
                      x="100%"
                      y={-1}
                      patternUnits="userSpaceOnUse"
                    >
                      <path d="M130 200V.5M.5 .5H200" fill="none" />
                    </pattern>
                  </defs>
                  <rect
                    width="100%"
                    height="100%"
                    strokeWidth={0}
                    fill="white"
                  />
                  <svg
                    x="100%"
                    y={-1}
                    className="overflow-visible fill-primaryVeryLight"
                  >
                    <path d="M-470.5 0h201v201h-201Z" strokeWidth={0} />
                  </svg>
                  <rect
                    width="100%"
                    height="100%"
                    strokeWidth={0}
                    fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)"
                  />
                </svg>
              </div>
              <h2 className="text-3xl font-bold tracking-tight text-primary">
                Get in touch
              </h2>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Please feel free to contact us, If you would like more
                information about our products.
              </p>
              <dl className="mt-10 space-y-4 text-base leading-7 text-gray-600">
                <div className="flex gap-x-4">
                  <dt className="flex-none">
                    <span className="sr-only">Address</span>
                    <MapPinIcon className="text-primary h-6 md:h-8" />
                  </dt>
                  <dd>Al Rahmaniya, Sharjah, U.A.E. P.O. Box: 79979</dd>
                </div>
                <div className="flex gap-x-4">
                  <dt className="flex-none">
                    <span className="sr-only">Telephone</span>
                    <PhoneArrowUpRightIcon className="text-primary h-6" />
                  </dt>
                  <dd>
                    <span className="textgray text-P14 font-poppins font-normal">
                      600 55 6666
                    </span>
                  </dd>
                </div>
                <div className="flex gap-x-4">
                  <dt className="flex-none">
                    <span className="sr-only">Email</span>
                    <EnvelopeIcon
                      className="h-7 w-6 text-primary"
                      aria-hidden="true"
                    />
                  </dt>
                  <dd>
                    <a
                      className="hover:text-cool-gray-600"
                      href="mailto:help@zulal.ae"
                    >
                      help@zulal.ae
                    </a>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          <form
            method="POST"
            onSubmit={handleSubmit}
            className="px-6 pb-24 pt-20 sm:pb-32 lg:px-8 lg:py-2"
          >
            <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
              <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                <div>
                  <label
                    htmlFor="firstName"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    First name
                  </label>
                  <div className="mt-2.5">
                    <input
                      type="text"
                      name="firstName"
                      onChange={onChange}
                      required
                      id="firstName"
                      autoComplete="given-name"
                      placeholder="First Name"
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-secondaryHover
                       placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="lastName"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    Last name
                  </label>
                  <div className="mt-2.5">
                    <input
                      type="text"
                      name="lastName"
                      onChange={onChange}
                      required
                      id="lastName"
                      autoComplete="family-name"
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-secondaryHover
                       placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="email"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    Email
                  </label>
                  <div className="mt-2.5">
                    <input
                      type="email"
                      onChange={onChange}
                      required
                      name="email"
                      id="email"
                      autoComplete="email"
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-secondaryHover
                       placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="tel"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    Phone number
                  </label>
                  <div className="mt-2.5">
                    <input
                      type="tel"
                      onChange={onChange}
                      required
                      name="tel"
                      id="tel"
                      autoComplete="tel"
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-secondaryHover
                       placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="message"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    Message
                  </label>
                  <div className="mt-2.5">
                    <textarea
                      name="message"
                      onChange={onChange}
                      id="message"
                      required
                      rows={4}
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-secondaryHover
                       placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                      defaultValue={""}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-8 flex flex-col justify-end">
                <div>
                  <button
                    type="submit"
                    className="rounded-md bg-secondary  px-3.5 py-2.5 text-center text-sm font-semibold text-textWhite shadow-sm hover:bg-secondaryHover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Send message
                  </button>
                </div>
                <div className="mt-4">
                  <span className="text-base font-bold text-red-500">
                    {message}
                  </span>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
