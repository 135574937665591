import React from "react";

function WaterQualities() {
  return (
    <div>
      <div className="mx-auto mt-[768px] md:mt-24 max-w-sm md:max-w-lg">
        <h2 className="py-5 text-7xl text-center items-center text-primary md:text-8xl dark:text-white">
          100% Pure Natural Water
          <span className="font-bold"> Rich in Minerals </span>
        </h2>
      </div>

      <div className="md:relative mt-[90px] mx-auto items-center justify-center flex flex-col gap-8">
        <div className="md:relative flex flex-col mx-auto mx-w-sm items-center justify-center gap-2 h-96 ">
          <div className="md:absolute lg:top-20 lg:-left-128 md:top-10 md:-left-128">
            <div className="text-2xl text-secondary font-poppins font-bold text-center md:text-right">
              Pure Elegance
            </div>

            <div className="text-base text-textgray font-normal max-w-xs font-poppins text-center md:text-right">
              Indulge in pure elegance with Zulal Water. Our carefully curated
              blend of minerals, including Calcium, Magnesium, and Potassium,
              transforms hydration into a nourishing experience, setting us
              apart in the world of bottled water.
            </div>
          </div>

          <div className="md:absolute lg:top-72 lg:-left-144 md:top-60 md:-left-144">
            <div className="text-2xl text-secondary font-poppins font-bold text-center md:text-right ">
              Nature's Gift
            </div>

            <div className="text-base text-textgray font-normal max-w-xs font-poppins text-center md:text-right">
              Sip the essence of nature with Zulal Water sourced from the
              pristine Hamdah Wells in Sharjah. Our commitment to delivering
              water as pure as its origin ensures every drop is a celebration of
              nature's gift.
            </div>
          </div>

          <div className="md:absolute lg:top-128 lg:-left-128 md:top-[28rem] md:-left-128">
            <div className="text-2xl text-secondary  max-w-sm font-poppins font-bold text-center md:text-right ">
              Precision in Every Drop
            </div>

            <div className="text-base text-textgray font-normal max-w-xs font-poppins text-center md:text-right">
              Precision meets perfection in our laboratory. Zulal Water
              undergoes rigorous testing, ensuring each drop meets the highest
              standards of purity and quality. Trust us for a refreshing
              experience you can taste.
            </div>
          </div>
        </div>

        <div className="mt-24 mb-24 md:mt-24 lg:mt-40 md:absolute md:top-0 md:-z-10">
          <img
            src="/images/waterQualities.svg"
            className="md:scale-150 md:-z-10 lg:scale-[2]"
            alt=""
          />
        </div>

        <div className="md:relative flex flex-col mx-auto mx-w-sm items-center justify-center gap-2 h-96  ">
          <div className="md:absolute lg:-top-[21rem] lg:-right-128 md:-top-[23rem] md:-right-128">
            <div className="text-2xl text-secondary font-poppins font-bold text-center md:text-left">
              Hygienic Excellence
            </div>

            <div className="text-base text-textgray font-normal max-w-xs font-poppins text-center md:text-left">
              Elevate your expectations with Zulal Water's hygienic excellence.
              Our manufacturing area reflects our dedication to cleanliness,
              mirroring our respect for the product, customers, and employees.
            </div>
          </div>

          <div className="md:absolute lg:-top-32 lg:-right-144 md:-top-48 md:-right-144">
            <div className="text-2xl text-secondary font-poppins font-bold text-center md:text-left ">
              Filtration Mastery
            </div>

            <div className="text-base text-textgray font-normal max-w-xs font-poppins text-center md:text-left">
              Embark on a journey of purity. Zulal Water employs cutting-edge
              high standards filtration technologies . Immerse yourself in
              consistently pure water that transcends ordinary hydration.
            </div>
          </div>

          <div className="md:absolute lg:top-24 lg:-right-128 md:top-5 md:-right-128">
            <div className="text-2xl text-secondary  max-w-sm font-poppins font-bold text-center md:text-left ">
              Effortless Purity
            </div>

            <div className="text-base text-textgray font-normal max-w-xs font-poppins text-center md:text-left">
              Experience purity at your fingertips. Zulal Water's
              state-of-the-art machines are not just efficient; they redefine
              convenience. Pure water is just a push of a button away – a
              testament to our commitment to making hydration effortless and
              delightful.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WaterQualities;
