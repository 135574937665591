import { Outlet, Navigate, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../context/user.context";

export const UserPrivateRoutes = () => {
  const { currentUser } = useContext(UserContext);
  const Auth = { token: false };

  if (currentUser.isloggedIn) {
    Auth.token = true;
  } else {
    Auth.token = false;
  }

  return Auth.token ? <Outlet /> : <Navigate to="/login" />;
};
