import React from "react";
import { Link } from "react-router-dom";
import HomePageCardItem from "./HomePageCardItem";

const HomeProductCard = ({ products }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 md:mt-10 md:mb-10 gap-12 justify-evenly">
      {products.map((product) => {
        if (product.homepage && product.instock) {
          return (
            <div
              key={product.id}
              className="relative flex rounded-2xl bg-cool-gray-100 w-[300px] h-[400px] scale-100
                   hover:scale-105 hover:bg-primaryLight transition ease-in-out duration-500
                   items-center justify-center
                   border-[1px] border-cool-gray-300"
            >
              <HomePageCardItem product={product} />
            </div>
          );
        }
      })}
    </div>
  );
};

export default HomeProductCard;
