import React, { useContext, useEffect, useRef } from "react";
import { UserContext } from "../../context/user.context";
import { useState } from "react";
import { CallCenterAPI } from "../../utils/callcenterAPI";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { Query } from "@syncfusion/ej2-data";
import * as data from "../../utils/Data/GeoLocations.json";

import "./syncFusionStyles.css";

export const UserAddress = () => {
  // country ComboBox instance
  let countryObj;
  // state ComboBox instance
  let stateObj;
  // city ComboBox instance
  let cityObj;
  // Area ComboBox instance
  let areaObj;

  //define the country ComboBox data
  const tempCountry = "country";
  //define the country ComboBox data
  const countryData = data[tempCountry];
  const [countryValue, setCountryValue] = useState(0);
  const countryFields = { text: "CountryName", value: "CountryID" };

  //define the state ComboBox data
  const tempState = "state";
  //define the state ComboBox data
  const stateData = data[tempState];
  // State Instance

  const stateFields = { text: "StateName", value: "StateID" };
  const [stateValue, setStateValue] = useState(0);

  //Cities Instance
  const tempCity = "cities";
  //define the city ComboBox data
  const cityData = data[tempCity];
  const citiesFields = { text: "CityName", value: "CityID" };
  const [citiesValue, setCitiesValue] = useState(0);

  //Area Instance
  //Cities Instance
  const tempArea = "areas";
  //define the city ComboBox data
  const areaData = data[tempArea];
  const areaFields = { text: "AreaName", value: "AreaID" };
  const [areaValue, setAreaValue] = useState(-1);
  const [areaText, setAreaText] = useState("");

  function countryChange() {
    // query the data source based on country ComboBox selected value
    console.log("Country Change");

    console.log(countryObj.value);

    console.log(stateData);

    let tempQuery = new Query().where("CountryID", "equal", countryObj.value);

    stateObj.query = tempQuery;
    // clear the existing selection in state ComboBox
    stateObj.text = null;
    // bind the property change to state ComboBox
    stateObj.dataBind();
    //clear the existing selection in city ComboBox
    cityObj.text = null;
    // disable the city ComboBox
    cityObj.enabled = true;
    // bind the property change to city ComboBox
    cityObj.dataBind();
  }
  function stateChange() {
    // query the data source based on state ComboBox selected value

    let tempQuery1 = new Query().where("StateID", "equal", stateObj.value);
    cityObj.query = tempQuery1;
    //clear the existing selection
    cityObj.text = null;
    cityObj.clear();

    // bind the property change to city ComboBox
    cityObj.dataBind();
  }

  const { currentUser, customerDetails, sonicAccessToken } =
    useContext(UserContext);
  const [userDetails, setUserDetails] = useState(customerDetails);

  const [messageCenter, setMessageCenter] = useState("");

  const getCustomerDetails = async (params) => {
    const objCustomerDetails = CallCenterAPI().getCustomerDetails(params);
    objCustomerDetails
      .then((detailres) => {
        console.log(detailres.data);
        //Object.assign(userDetails, detailres.data);

        //userDetails = structuredClone(detailres.data);
        setUserDetails(detailres.data);

        console.log(userDetails);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  useEffect(() => {
    if (currentUser.customerCode !== null && currentUser.customerCode !== "") {
      if (sessionStorage.getItem("customerToken") !== null) {
        const token = sessionStorage.getItem("customerToken");

        console.log(token);

        const params = { customerToken: token };

        getCustomerDetails(params);

        console.log(userDetails);

        setCountryValue(userDetails.countryID);
        setStateValue(userDetails.stateID);
        setCitiesValue(userDetails.cityID);
        setAreaValue(userDetails.areaId);
        console.log(`userDetails.areaId = ${userDetails.areaId}`);
        console.log(`userDetails.AreaId = ${userDetails.AreaId}`);
      } else {
        console.log("Redirect to Login Page");
      }
    } else {
      console.log("Rediret to some other Page");
    }
  }, [currentUser]);

  const onChangeHandler = (e) => {
    //setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log(countryObj.value);
    console.log(stateObj.value);

    // citiesObj.current.value = 13;

    // initally change event not triggered, so manually call the corresponding function

    // stateObj.current.index = 0;
    // stateObj.current.text = "SHARJAH";
  };

  return (
    <div className="mx-auto mr-4 ml-4 mb-28">
      <div className="text-navarbarText text-3xl font-poppins font-medium mb-8">
        User Address
        <p className="text-base text-darkTextColor font-poppins font-normal">
          Please update your current Location to serve you better
        </p>
      </div>
      <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
        {/** Building Name  */}
        <div>
          <label
            htmlFor="buildingName"
            className="block text-sm font-semibold leading-6 text-gray-900"
          >
            Building Name
          </label>
          <div className="mt-2.5">
            <input
              type="text"
              name="buildingName"
              value={userDetails.bldgName}
              id="buildingName"
              autoComplete="buildingName"
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-secondaryHover
         placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        {/** Flat nuumber  */}
        <div>
          <label
            htmlFor="flatno"
            className="block text-sm font-semibold leading-6 text-gray-900"
          >
            Flat / Villa Number
          </label>
          <div className="mt-2.5">
            <input
              type="text"
              name="flatno"
              value={userDetails.driverName}
              id="flatno"
              autoComplete="flatno"
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-secondaryHover
             placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        {/** landmark Name  */}
        <div>
          <label
            htmlFor="landmark"
            className="block text-sm font-semibold leading-6 text-gray-900"
          >
            Nearby Landmark
          </label>
          <div className="mt-2.5">
            <input
              type="text"
              name="landmark"
              value={userDetails.landmark}
              id="landmark"
              autoComplete="landmark"
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-secondaryHover
                 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div>
          <label
            htmlFor="countryList"
            className="block text-sm font-semibold leading-6 text-gray-900"
          >
            Select Country
          </label>
          <div className="mt-2.5">
            <ComboBoxComponent
              id="countryList"
              placeholder="select country"
              fields={countryFields}
              dataSource={countryData}
              value={countryValue}
              ref={(combobox) => {
                countryObj = combobox;
              }}
              change={countryChange.dataBind(this)}
            />
          </div>
        </div>

        <div>
          <label
            htmlFor="cmbstate"
            className="block text-sm font-semibold leading-6 text-gray-900"
          >
            Select State
          </label>
          <div className="mt-2.5">
            <ComboBoxComponent
              id="cmbstate"
              dataSource={stateData}
              value={stateValue}
              allowCustom={false}
              fields={stateFields}
              change={stateChange.bind(this)}
              placeholder="Select State"
            />
          </div>
        </div>

        <div>
          <label
            htmlFor="cmbCities"
            className="block text-sm font-semibold leading-6 text-gray-900"
          >
            Select Cities
          </label>
          <div className="mt-2.5">
            <ComboBoxComponent
              id="cmbCities"
              ref={(combobox) => {
                cityObj = combobox;
              }}
              value={citiesValue}
              dataSource={cityData}
              allowCustom={false}
              fields={citiesFields}
              placeholder="select cities"
            />
          </div>
        </div>

        <div>
          <label
            htmlFor="cmbAreas"
            className="block text-sm font-semibold leading-6 text-gray-900"
          >
            Select Area
          </label>
          <div className="mt-2.5">
            <ComboBoxComponent
              id="cmbAreas"
              value={areaValue}
              dataSource={areaData}
              allowCustom={false}
              fields={areaFields}
              placeholder="Select Area"
            />
          </div>
        </div>

        <div>
          <label
            htmlFor="RouteInfo"
            className="block text-sm font-semibold leading-6 text-gray-900"
          >
            Route Information
          </label>
          <div className="mt-2.5">
            <input
              name="route"
              readOnly
              id="route"
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-secondaryHover
             placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="geolocation"
            className="block text-sm font-semibold leading-6 text-gray-900"
          >
            Current Location
          </label>
          <div className="mt-2.5">
            <input
              name="geolocation"
              id="geolocation"
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-secondaryHover
           placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
            />
          </div>
        </div>
      </div>
      <div className="mt-8 flex justify-end">
        <button
          type="submit"
          onClick={handleSubmit}
          className="rounded-md bg-secondary  px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-secondaryHover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Update Location
        </button>
      </div>
      <span className="text-xl font-bold ">Message center</span>
    </div>
  );
};
