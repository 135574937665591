import React from "react";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { useSwiper } from "swiper/react";

export const NAMNextButton = () => {
  const swiper = useSwiper();

  return (
    <button
      id="btnNext1"
      onClick={() => swiper.slideNext()}
      className="invisible sm:visible w-10 h-10 text-primary rounded-full bg-secondary top-2/4
      text-center items-center justify-center hover:bg-secondaryHover hover:text-white "
      style={{ "text-align": "-webkit-center" }}
    >
      <ArrowRightIcon className="w-6 h-6" />
    </button>
  );
};
