import React from "react";
import { ArrowRightCircleIcon } from "@heroicons/react/24/solid";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Mousewheel, Navigation, Pagination, Scrollbar } from "swiper";

import { NAMPrevButton } from "./NAMPrevButton";
import { NAMNextButton } from "./NAMNextButton";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import "./NewsAndMediaCards.css";

const NewsAndMediaCards = ({ news }) => {
  return (
    <div>
      <Swiper
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          375: {
            slidesPerView: 1,
          },
          480: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          834: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          1280: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
        }}
        modules={[Pagination, Navigation, Scrollbar, Mousewheel, A11y]}
        className="max-w-md sm:max-w-[700px] md:max-w-6xl lg:max-w-7xl "
        style={{ "text-align": "-webkit-center" }}
      >
        {news.map((item) => (
          <SwiperSlide key={item.id}>
            <div className="flex flex-col w-80 h-auto mt-16 items-center justify-items-center bg-primaryVeryLight rounded-lg">
              <div className=" rounded-lg">
                <img
                  src={item.thumbnailurl}
                  alt={item.title}
                  className="rounded-lg mt-4"
                />
              </div>
              <div className="text-center p-4">
                <span className="font-semibold text-base">{item.title}</span>
              </div>
              <div className="text-left p-4">
                <span className="font-regular text-base">
                  {item.description}
                </span>
              </div>
              <div className="flex w-full p-4 text-right justify-between ">
                <div>
                  <span className="font-bold text-C12">{item.date}</span>
                </div>
                <div>
                  <a
                    href="#"
                    className="flex flex-row gap-1 items-center justify-center justify-items-center text-primary  hover:text-primaryHover"
                  >
                    <span className="font-bold text-C12"> Read More</span>
                    <div>
                      <ArrowRightCircleIcon className="w-6 h-6" />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}

        <div className="h-5"></div>
        <NAMPrevButton />
        <NAMNextButton />
        <div className="h-10"></div>
      </Swiper>
    </div>
  );
};

export default NewsAndMediaCards;
