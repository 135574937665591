import axios from "axios";
import { Sonic_FileUpload_URL } from "./config";

// Function to upload file along with accountno and filename
export const uploadFileToServer = async (req, res) => {
  try {
    const response1 = axios
      .post(Sonic_FileUpload_URL, req)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return error.message;
      });

    console.log(response1);

    return response1;

    // const response = await axios.post(Sonic_FileUpload_URL, formData, {
    //   headers: { "Content-Type": "multipart/form-data" },
    // });

    // Handle success, returning the response if necessary
  } catch (error) {
    // Handle error, throw it so the calling function can handle it
    throw new Error("Error uploading file: " + error.message);
  }
};
