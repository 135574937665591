import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { CallCenterAPI } from "../../utils/callcenterAPI";
import { NetworkInt } from "../../utils/NetworkInt";

import ScaleLoader from "react-spinners/ScaleLoader";

export const TopUpConfirmation = () => {
  let query = useQuery();

  const [paymentState, setPaymentState] = useState("");
  const [orderAmount, setOrderAmount] = useState(-1);
  const [customerCode, setCustomerCode] = useState("");
  const [orderLoaded, setOrderLoaded] = useState(false);
  const [customerToken, setCustomerToken] = useState("");
  const [okToTopUp, setOKToTopUp] = useState(false);
  const [alreadyUpdated, setAlreadyUpdated] = useState("");

  const [orderId, setOrderId] = useState("");

  const [loading, setLoading] = useState(false);

  // A custom hook that builds on useLocation to parse
  // the query string for you.
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const getOrderInformation = async (orderId) => {
    const NIAccessToken = NetworkInt().getAccessToken();

    setLoading(true);
    console.log(NIAccessToken);
    NIAccessToken.then((res) => {
      const accessToken = res.access_token;
      const params = { accessToken: accessToken, orderId: orderId };
      const OrderInfo = NetworkInt().getOrder(params);

      OrderInfo.then((orderInfo) => {
        console.log(orderInfo);

        if (orderInfo.code !== "ERR_BAD_REQUEST") {
          console.log(orderInfo._embedded.payment[0].state);
          console.log(orderInfo._embedded.payment[0].originIp);

          const orderState = orderInfo._embedded.payment[0].state;
          const orderCustomerRef =
            orderInfo.merchantAttributes.merchantOrderReference;

          setPaymentState(orderInfo._embedded.payment[0].state); //state:"PURCHASED", state: "FAILED", state:"STARTED"
          setOrderAmount(orderInfo.amount.value / 100);
          //setCustomerCode(orderInfo.merchantAttributes.merchantOrderReference);

          setCustomerCode(orderCustomerRef);
          //setCustomerCode("111937413");
          setOrderLoaded(true);

          //setPaymentState("STARTED");

          switch (orderState) {
            case "PURCHASED":
              setOKToTopUp(true);
              break;
            case "FAILED":
              setOKToTopUp(false);
              break;

            case "STARTED":
              setOKToTopUp(false);
              break;

            default:
              setOKToTopUp(false);
              break;
          }
        } else {
          console.log("Order Reference is incorrect or Maually changed");
          setLoading(false);
        }

        setLoading(false);
      }).catch((error) => {
        console.log(error.message);
        setLoading(false);
      });
    }).catch((err) => {
      console.log(err.message);
      setLoading(false);
    });
  };

  // const firebaseOrderInfo = async () => {
  //   getTopupByOrderId(orderId)
  //     .then((res) => {
  //       console.log(res);

  //       if (res.length > 0) {
  //         //Order Exists and Update the Status as NI Order State
  //         //console.log(`paymentState:${paymentState}`);
  //         UpdateTopUpOrderStatus(customerCode, orderId, {
  //           Status: paymentState,
  //         });
  //       } else {
  //         //Order Not Exists Dont do anything
  //         // console.log("Creating order in firebase");
  //         // console.log(customerCode);
  //         // console.log(orderId);
  //         // console.log(paymentState);
  //         // console.log(orderAmount);
  //         saveTopUpOrderValue(customerCode, orderId, {
  //           Amount: orderAmount,
  //           Status: paymentState,
  //         })
  //           .then((res) => {
  //             if (res === "Success") {
  //               console.log("Save TopUpOrder in FB");
  //             } else if (res === "Error") {
  //               console.log("TopUpOrder not saved in FB");
  //             }
  //           })
  //           .catch((err) => {
  //             console.log("Got Error by saving TopUpOrder in FB");
  //           });
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });

  //   setLoading(false);
  // };

  function ICashTopUp(params) {
    const customerICash = CallCenterAPI().getCustomerICash(params);

    //console.log(customerICash);

    customerICash
      .then((response) => {
        const money = response.data.balance;
        const cardNumber = response.data.card_Number;

        // console.log(customerToken);
        // console.log(cardNumber);
        // console.log(orderAmount);
        // console.log(orderId);

        const TopUpDetails = {
          accountNumber: cardNumber,
          transactionNumber: orderId,
          paymentRefNo: orderId,
          rechargeAmount: orderAmount,
        };

        const iparams = {
          customerToken: customerToken,
          TopUpDetails: TopUpDetails,
        };

        //console.log(iparams);

        const ICashTopUp = CallCenterAPI().ICashTopUp(iparams);
        //{ data: { newBalance: 600 }, message: 'Success', status: 0 }
        ICashTopUp.then((response) => {
          //console.log(response);
          if (response.data === null) {
            //console.log(response.message);
            setAlreadyUpdated(response.message);
            //setAlreadyUpdated(" is : 700 ");
          } else {
            setAlreadyUpdated(" is :" + response.message);
          }
        }).catch((err) => {
          console.log(err);
        });

        //console.log(`ICAsH: ${money}`);
        //setICashBalance(money);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  useEffect(
    () => {
      if (customerCode !== "" && okToTopUp === true) {
        const cCode = { customerCode: customerCode };
        const callRes = CallCenterAPI().getSonicCustomerAccessToken(cCode);
        callRes.then((res1) => {
          if (res1.status === 0 && res1.message === "Success") {
            const token = res1.data.token;
            setCustomerToken(token);
          }
        });
        //console.log(`okToTopUp:${okToTopUp}`);
        //console.log(`PaymentState:${paymentState}`);
      } else {
        //console.log(`okToTopUp:${okToTopUp}`);
        //console.log(`PaymentState:${paymentState}`);
      }
    },
    [customerCode],
    [okToTopUp]
  );

  useEffect(() => {
    const orderId = query.get("ref");
    if (orderId && orderId.length === 36) {
      //console.log(`Order ${orderId}`);
      setOrderId(orderId);
      setLoading(true);
    } else {
      //console.log("Order Reference not there");
      setAlreadyUpdated("Order Ref : Order Reference is required");
      setLoading(false);
    }
  }, [orderId]);

  useEffect(() => {
    if (orderId !== "") {
      //console.log("runs order Confirmation");
      getOrderInformation(orderId);
    }
  }, [orderId]);

  // useEffect(() => {
  //   if (orderLoaded === true) {
  //     //console.log("firbase funcion loaded");
  //     //firebaseOrderInfo();
  //     //console.log("firbase funcion ended");
  //   }
  // }, [orderLoaded]);

  useEffect(
    () => {
      if (customerToken !== "" && okToTopUp === true) {
        const params = { customerToken: customerToken };
        ICashTopUp(params);
      } else {
        // console.log(`okToTopUp:${okToTopUp}`);
        // console.log(`PaymentState:${paymentState}`);
      }

      setLoading(false);
    },
    [customerToken],
    [okToTopUp]
  );

  return (
    <div className="mb-60">
      <div className="flex flex-col mx-auto items-center justify-center text-center justify-items-center">
        <div>
          <ScaleLoader
            color="#36d7b7"
            height={40}
            loading={loading}
            radius={2}
            width={4}
          />
        </div>
        {paymentState === "PURCHASED" && okToTopUp === true ? (
          <div className="flex flex-col md:flex-row mx-auto items-center justify-center text-center justify-items-center">
            <div>
              <img
                src="../../images/TopUpConfirmation.jpg"
                width={450}
                alt="Top Up confirmation"
              />
            </div>
            <div className="max-w-md">
              <span className="text-6xl m-3 font-bold text-center text-primary md:text-9xl md:font-extrabold md:text-left md:max-w-xl">
                Thank You ! <br />
                <span className="text-2xl font-normal md:text-5xl m-3">
                  {" "}
                  your ICash Wallet {alreadyUpdated.split(":")[0]} <br />
                </span>
                {alreadyUpdated.split(":")[1]}
              </span>
            </div>
          </div>
        ) : (
          <div className="flex flex-col md:flex-row mx-auto items-center justify-center text-center justify-items-center">
            <div>
              <img
                src="../../images/OrderCancel.jpg"
                width={450}
                alt="Top Up confirmation"
              />
            </div>
            <div className="max-w-md">
              <span className="text-6xl m-3 font-bold text-center text-primary md:text-9xl md:font-extrabold md:text-left md:max-w-xl">
                Sorry ! <br />
                <span className="text-2xl font-normal md:text-5xl m-3">
                  {" "}
                  your ICash Wallet <br />
                </span>
                NOT updated
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
