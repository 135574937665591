import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

export const Slide4 = () => {
  return (
    <React.Fragment>
      <div className="mx-auto px-5 font-poppins mt-1 flex flex-col items-center md:items-start justify-evenly md:flex-row">
        {/* Left div item md:w-1/2 */}
        <motion.div
          variants={{
            hidden: { opacity: 0, y: 100 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          animate="visible"
          transition={{ duraion: 0.3, delay: 0.1 }}
          className="flex flex-col items-start md:mt-2 space-y-8"
        >
          <div className="flex flex-col justify-center md:justify-start">
            <div className="absolute -z-10">
              <img src="../../images/circlebig.png" alt="" />
            </div>
            <span className="max-w-md text-5xl mt-8 font-bold text-center text-primary md:text-8xl md:font-extrabold md:text-left md:max-w-xl">
              Partners Promotions
            </span>
            <p className="max-w-md font-normal text-xl text-justify text-darkTextColor  md:max-w-xl">
              You can now enjoy exclusive discounts offered to our partners'
              network. One time registration process is required.
            </p>

            <div className="flex flex-row gap-4">
              <div className="flex mt-8 justify-center md:justify-start">
                <Link
                  to="/productsrange"
                  href="#about"
                  className="inline-flex items-center justify-center w-auto md:w-44 px-5 py-3 mr-3 text-base font-medium text-center 
                     text-textWhite rounded-full bg-primary   hover:bg-primaryHover focus:ring-4 focus:ring-primary-300 dark:focus:ring-primaryLight"
                >
                  Order Now
                  <svg
                    className="w-5 h-5 ml-2 -mr-1"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </Link>
              </div>

              <div className="flex mt-8 justify-center md:justify-start">
                <Link
                  to="/howitworks"
                  className="inline-flex items-center justify-center w-auto md:w-44 px-5 py-3 mr-3 text-base font-medium text-center 
                     text-primary rounded-full border border-1  hover:bg-secondaryVeryLight focus:ring-4 focus:ring-primary-300 dark:focus:ring-primaryLight"
                >
                  How it works
                </Link>
              </div>
            </div>
          </div>
        </motion.div>

        {/* right div item md:w-1/2 */}
        <motion.div
          variants={{
            hidden: { opacity: 0, y: 100 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          animate="visible"
          transition={{ duraion: 0.5, delay: 0.3 }}
          className="mt-8 sm:mb-24 md:mt-1"
        >
          <div className="flex flex-col justify-center md:justify-start">
            <div className="absolute -z-10">
              <img src="../../images/circlebig.png" alt="" />
            </div>
            <div className="flex flex-col justify-center items-center">
              <div>
                <img
                  src="../../images/sliderimages/sewa_logo.png"
                  className="h-36"
                  alt=""
                />
              </div>

              <div className="flex flex-col gap-2 mt-2 sm:flex-row justify-center items-center">
                <div>
                  <img
                    src="../../images/sliderimages/Esaad_logo.png"
                    alt=""
                    className="h-36"
                  />
                </div>
                <div>
                  <img
                    src="../../images/sliderimages/Faaza_logo.png"
                    alt=""
                    className="h-36"
                  />
                </div>
                <div>
                  <img
                    src="../../images/sliderimages/Waffer_logo_1.png"
                    alt=""
                    className="h-36"
                  />
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </React.Fragment>
  );
};
