import React from "react";

const SafeWater = () => {
  return (
    <div>
      <section>
        <div className="container mx-auto font-poppins mt-10 flex flex-col items-center text-center ">
          {/* Left div item md:w-1/2 */}

          <h1 className="py-5 px-5 text-6xl md:text-9xl font-normal text-primary md:max-w-2xl  dark:text-white">
            <span className="text-7xl font-bold md:text-9xl">
              Safe and Pure{" "}
            </span>
            Water for{" "}
            <span className="text-6xl font-bold md:text-9xl">
              Healthy Life.
            </span>
          </h1>
        </div>

        <div className="grid grid-cols-2 mt-10 mb-10 md:grid-cols-4 gap-1 place-items-center">
          <div className="grid gap-1">
            <div>
              <img
                className="h-auto max-w-full rounded-lg"
                src="../../images/life/zulal_bottle_home.png"
                alt="1"
              />
            </div>
          </div>
          <div className="grid gap-1">
            <div>
              <img
                className="h-auto max-w-full rounded-lg"
                src="../../images/life/zulal_bottle_pad.png"
                alt="2"
              />
            </div>
            <div>
              <img
                className="h-auto max-w-full rounded-lg"
                src="../../images/life/img_032.png"
                alt="3"
              />
            </div>
          </div>
          <div className="grid gap-1">
            <div>
              <img
                className="h-auto max-w-full rounded-lg"
                src="../../images/life/img_01111.png"
                alt="4"
              />
            </div>
          </div>
          <div className="grid gap-1">
            <div>
              <img
                className="h-auto max-w-full rounded-lg"
                src="../../images/life/img_051.png"
                alt="5"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SafeWater;
