import React from "react";
import { ShoppingCartIcon } from "@heroicons/react/24/outline";
import { useContext } from "react";
import { CartContext } from "../../context/cart.context";

const CartIconComponent = ({ onClick }) => {
  const { cartCount } = useContext(CartContext);

  return (
    <div>
      <button
        onClick={() => {
          onClick(false);
        }}
        className="relative inline-block rounded-full bg-primary h-8 w-8 xl:w-10 xl:h-10 text-textWhite
            hover:bg-primaryHover focus:outline-none focus:ring-2 focus:ring-secondary"
      >
        <ShoppingCartIcon className="mx-auto w-6 h-6" />
        <span
          className="absolute right-[-5px] top-[-5px] block h-6 w-6 rounded-full font-semibold
                          text-base text-primary place-items-center bg-yellow ring-1 ring-yellow"
        >
          {cartCount}
        </span>
      </button>
    </div>
  );
};

export default CartIconComponent;
